@mixin search_field_placeholder {
    opacity: 1;
    font-size: 16px;
    color: #97A4B1;
}

.Search {
    overflow: visible !important;
}

.patient_search {
    position: relative;
    .patient_search__suggestions {
        position: absolute;
        left: 0;
        top: 100%;
        width: 351px;
        border: 1px solid #D9E3E7;
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 24px;
        background-color: #fff;
        font-size: 16px;
        color: #97A4B1;
        z-index: 1;
        &__list {
            list-style: none;
            margin: 0;
            padding: 10px 25px;
            li {
                margin-bottom: 5px;
                a {
                    @include search_field_placeholder();
                }
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.patient_panel {
    display: flex;
    justify-content: space-between;
    &__left {
        flex: 0 0 50%;
        max-width: 50%;
        .Search {
            width: 351px;
            .Search__input {
                height: 49px;
                width: 100%;
                border: 1px solid #D9E3E7;
                border-radius: 24px;
                background-color: #FFFFFF;
                box-shadow: none;
                padding: 0 18px 0 25px;
                @include search_field_placeholder();
                &::-webkit-input-placeholder {
                    @include search_field_placeholder();
                }
                &::-ms-input-placeholder {
                    @include search_field_placeholder();
                }
                &:-ms-input-placeholder {
                    @include search_field_placeholder();
                }
                &::-moz-placeholder {
                    @include search_field_placeholder();
                }
                &:-moz-placeholder {
                    @include search_field_placeholder();
                }
                &::placeholder {
                    @include search_field_placeholder();
                }
            }
            .Search__input__icon {
                right: 20px;
                left: auto;
            }
            &.search_opened {
                .Search__input {
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                }
            }
        }
    }
    &__right {
        display: flex;
        justify-content: flex-end;
        flex: 0 0 50%;
        max-width: 50%;
        .patient_panel__cta {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 49px;
            width: 200px;
            border-radius: 24px;
            background-color: #00A999;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            span {
                margin-left: 8px;
            }
        }
    }
}