@import '../../style/breakpoints';

.NavContainer {
	--content-width: 1200px;
  --nav-padding: 8px;
  --content-padding: 24px 32px;

	width: 100%;
	max-width: var(--content-width);
  padding: 0;
  background-color: var(--white);
	display: flex;
	flex-direction: column;
	border: none;
	margin: 0 auto;
	min-height: 100%;
	box-shadow: var(--elevation-5);
	border-radius: 3px;

  @media (min-width: $xs-to-sm) {
    --nav-padding: 16px;
    --content-padding: 32px 48px;
	}

	&__placeholder {
		padding: 0 64px;
	}

	&__title.important {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 8px;
		margin: 0 80px;
		text-align: center;
	
		svg {
			margin-right: 8px;
		}
	
		.IconStack {
			margin-right: 8px;
	
			svg {
				margin-right: 0;
			}
		}
		
		span {
			padding: 0;
		}
	}

	&__sub-title {
		font-size: 16px;
		text-align: center;
	}
	

	&__loading-dots {
		&__nav {
			margin-top: 0;
			color: var(--white);
			font-size: 12px;
			padding: 3px;
	
			> svg {
				margin: 0 4px;
			}
		}
	
		&__page {
			font-size: 24px;
	
			> svg {
				margin: 0 12px;
			}
		}
	}
	
	&__nav {
		flex: 0 0 auto;
		min-height: calc(2*var(--nav-padding) + 62px);
		display: flex;
		z-index: var(--subnav-index);
		position: sticky;
		top: var(--banner-height); //Offline notification: found in /src/components/MainContainer.scss
		flex-direction: row;
		justify-content: center;
		font-size: var(--font-size-6);
		background-color: var(--primary-color);
		box-shadow: var(--elevation-1);
		padding: 0 var(--nav-padding);
	
		a, button {
			border-radius: 6px;
	
			svg {
				margin-right: 8px;
			}
	
			span {
				padding: 0;
				margin: 0;
				display: none;
	
				@media (min-width: $sm-to-md) {
					display: block;
				}
			}
		}
	
		.back {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 16px;
		}
			
		.right {
			display: flex;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 16px;
		}
	
		span {
			display: inline-flex;
			align-items: center;
			padding: 16px 16px;
			color: var(--white);
			align-self: center;
	
			svg {
				margin-right: 8px;
			}
		}
  }
  
  &__content {
		flex: 1;
		display: flex;
		flex-direction: column;
    padding: var(--content-padding);
  }
}