@mixin form_elements_text_font {
    opacity: 1;
    font-size: 14px !important;
    color: #2E2E2E !important;
}

.error_panel {
    padding: 0 15px;
    .error_panel__inner {
        color: #fff;
        text-align: center;
        background-color: red;
        padding: 15px 0;
        border-radius: 4px;
    }
}

.theme_empty_data {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 600;
    padding: 20px 10px;
    margin-top: 25px;
}

.theme_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 24px;
    font-size: 14px;
    font-weight: 500;
    color: #fff !important;
    border-radius: 24px;
    background-color: #00A999;
    border: solid 1px #00A999;
    &--inline {
        display: inline-flex;
    }
    &.disabled {
        opacity: .6;
    }
    &.small {
        height: 32px;
        font-size: 14px;
    }
    &.medium {
        height: 48px;
        font-size: 16px;
    }
    svg, img {
        margin-right: 15px;
    }
    &:hover, &:focus, &:active {
        color: #fff;
    }
    &.theme_btn--white {
        color: #2E2E2E !important;
        background-color: transparent;
        border-color: #2E2E2E;
        &:hover, &:focus, &:active {
            color: #fff !important;
            background-color: #00A999;
            border-color: #00A999;
        }
    }
    &.theme_btn--white-green {
        color: #00A999 !important;
        background-color: #fff;
        border-color: #00A999;
        &:hover, &:focus, &:active {
            color: #fff !important;
            background-color: #00A999;
            border-color: #00A999;
        }
    }
}

.app {
    .hidden_content {
        display: none;
    }
    
    .sympthoms_slide {
        .sympthoms_slide__body {
            // padding: 15px;
            height: 100%;
            .graph_panel_sheet {
                position: relative;
                width: 100%;
                height: 419px;
                border: 1px solid #E6EAEC;
                border-radius: 3px;
                background-color: #fff;
                margin-bottom: 10px;
                .full_mode {
                    position: absolute;
                    z-index: 2;
                    right: 5px;
                    bottom: 5px;
                    height: 31px;
                    width: 31px;
                    border-radius: 2px;
                    background-color: #00A999;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .graph_panel_data {
                position: relative;
                height: 100px;
                width: 100%;
                border: 1px solid #E8E8E8;
                border-radius: 3px;
                background-color: #FBF9F8;
                padding: 15px;
                overflow-y: auto;
                &__list {
                    padding: 0;
                    margin: 0;
                    list-style-position: inside;
                    li {
                        color: #4B4B4B;
                        font-size: 14px;
                        word-break: break-all;
                    }
                }
            }
        }
    }
    
    .theme_relevance {
        display: flex;
        justify-content: space-between;
        .theme_relevance__check {
            position: relative;
            height: 20px;
            width: 20px;
            border: 1px solid #00A999;
            background-color: #F8FAFB;
            border-radius: 50%;
            margin-right: 5px;
            &--checked {
                &:before {
                    content: '';
                    position: absolute;
                    left: 3px;
                    top: 3px;
                    width: 12px;
                    height: 12px;
                    background-color: #00A999;
                    border-radius: 50%;
                }
            }
            &--clickable {
                cursor: pointer;
            }
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    
    .theme_header {
        background-color: #F8FAFB;
        box-shadow: 0 5px 20px 0 rgba(0,0,0,0.15);
        padding: 24px 32px 0;
        .theme_header__info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .theme_header__info__left {
                flex: 0 0 50%;
                max-width: 50%;
                .info_cell {
                    display: flex;
                    align-items: center;
                    &__icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
                        margin-right: 12px;
                        background-color: #fff;
                    }
                    &__text {
                        p {
                            font-size: 13px;
                            color: #2B2B2B;
                            margin-bottom: 0;
                            &:nth-child(1) {
                                font-size: 18px;
                                font-weight: 600;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
            .theme_header__info__right {
                flex: 0 0 50%;
                max-width: 50%;
                .control_cell {
                    display: flex;
                    justify-content: flex-end;
                    &__icon {
                        margin-right: 12px;
                        a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 48px;
                            height: 48px;
                            border-radius: 50%;
                            border: solid 1px #2E2E2E;
                            transition: none;
                            svg {
                                width: 24px;
                                height: 24px;
                                color: #2E2E2E;
                            }
                            &:hover,
                            &.active {
                                border-color: #00A999;
                                svg {
                                    color: #00A999;
                                }
                            }
                        }
                    }
                    &__btn {
                        a {
                            display: flex;
                            align-items: center;
                            height: 48px;
                            padding: 0 24px;
                            font-size: 16px;
                            font-weight: 500;
                            color: #fff;
                            border-radius: 24px;
                            background-color: #00A999;
                        }
                    }
                }
            }
            &.full-width {
                justify-content: flex-end;
                .theme_header__info__right {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }
    
    .theme_radio_group {
        display: flex;
        &.mb_15 {
            margin-bottom: 15px;
        }
        .theme_radio {
            margin-right: 15px;
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    .theme_radio {
        input {
            display: none;
        }
        label {
            position: relative;
            font-size: 14px;
            color: #2B2B2B;
            margin-bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding-left: 26px;
            .check_round {
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid #757575;
                background-color: #fff;
                &__inside {
                    opacity: 0;
                    position: absolute;
                    top: calc(50% - 6px);
                    left: calc(50% - 6px);
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: #00A999;
                }
            }
            &::first-letter {
                text-transform: lowercase;
            }
        }
        input:checked + label {
            .check_round__inside {
                opacity: 1;
            }
        }
        input:checked + label::before {
            .check_round {
                border-color: #00A999;
            }
        }
        &__small {
            label {
                justify-content: left;
                padding-left: 22px;
                font-size: 13px;
                .check_round {
                    width: 16px;
                    height: 16px;
                    &__inside {
                        left: calc(50% - 4px);
                        top: calc(50% - 4px);
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }
    
    .theme_checkbox_xl {
        input {
            display: none;
        }
        label {
            position: relative;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            padding-left: 46px;
            color: #2B2B2B;
            font-size: 18px;
            font-weight: 600;
            &::before {
                content: "";
                position: absolute;
                left: 0;
                width: 34px;
                height: 34px;
                border-radius: 4px;
                border: 1px solid #D9E3E7;
                background-color: #fff;
            }
            &::after {
                content: "";
                opacity: 0;
                position: absolute;
                left: 11px;
                top: 3px;
                width: 10px;
                height: 16px;
                transform: rotate(45deg);
                border: solid #000;
                border-width: 0 2px 2px 0;
            }
        }
        input:checked + label::after {
            opacity: 1;
            border-color: #00A999;
        }
        input:checked + label::before {
            border-color: #00A999;
        }
    }
    
    .theme_checkbox {
        input {
            display: none;
        }
        label {
            position: relative;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            padding-left: 30px;
            color: #2B2B2B;
            font-size: 14px;
            font-weight: 400;
            &::before {
                content: "";
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
                border-radius: 4px;
                border: 1px solid #D9E3E7;
                background-color: #fff;
            }
            &::after {
                content: "";
                opacity: 0;
                position: absolute;
                left: 8px;
                top: 7px;
                width: 6px;
                height: 10px;
                transform: rotate(45deg);
                border: solid #000;
                border-width: 0 2px 2px 0;
            }
        }
        input:checked + label::after {
            opacity: 1;
            border-color: #00A999;
        }
        input:checked + label::before {
            border-color: #00A999;
        }
    }
    
    .theme_tabs {
        display: flex;
        margin-top: 25px;
        margin-bottom: 27px;
        box-shadow: inset 0 -1px 0 0 #DEE0E1;
        a {
            position: relative;
            display: flex;
            flex: 0 0 33.3333333%;
            max-width: 33.3333333%;
            justify-content: center;
            align-items: center;
            height: 50px;
            font-size: 13px;
            font-weight: 500;
            color: #2E2E2E;
            &.active, &:hover {
                color: #00A999;
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    background-color: #00A999;
                    width: 100%;
                    height: 3px;
                }
            }
        }
    }
    
    .theme_table {
        .table {
            thead {
                tr {
                    border-bottom: none;
                }
            }
            tbody {
                tr {
                    border-bottom: none;
                    td {
                        vertical-align: baseline;
                        .theme_table_bold {
                            font-weight: 600;
                        }
                        .theme_relevance {
                            width: 120px;
                        }
                        .theme_table__controls {
                            display: flex;
                            a {
                                margin-right: 27px;
                                &:last-child {
                                    margin-right: 0;
                                }
                                svg {
                                    display: block;
                                    width: 24px;
                                    height: 24px;
                                    color: #4B4B4B;
                                }
                            }
                        }
                    }
                    &:nth-child(odd) {
                        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
                        border-radius: 4px;
                        td {
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }
    
    .theme_text_input {
        height: 51px !important;
        border: 1px solid #E6EAEC !important;
        border-radius: 4px !important;
        background-color: #fff !important;
        box-shadow: none !important;
        @include form_elements_text_font();
        &::-webkit-input-placeholder {
            @include form_elements_text_font();
        }
        &::-ms-input-placeholder {
            @include form_elements_text_font();
        }
        &:-ms-input-placeholder {
            @include form_elements_text_font();
        }
        &::-moz-placeholder {
            @include form_elements_text_font();
        }
        &:-moz-placeholder {
            @include form_elements_text_font();
        }
        &::placeholder {
            @include form_elements_text_font();
        }
    }
}
