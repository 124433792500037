@mixin form_elements_placeholder {
    opacity: 1;
    font-size: 14px;
    color: #97A4B1;
}

.patient_form {
    display: block;
    @media (min-width: 895px) {
        display: flex;
        flex-direction: row;
    }
    .patient_form__left {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0px;
        margin-bottom: 30px;
        @media (min-width: 895px) {
            flex: 0 0 50%;
            max-width: 50%;
            padding-right: 22px;
            margin-bottom: 0px;
        }
    }
    .patient_form__right {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0px;
        @media (min-width: 895px) {
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: 22px;
        }
    }
    .form_element {
        width: 100%;
        margin-bottom: 10px;
        .Form__input {
            margin-bottom: 0;
            margin-top: 0px;
            label {
                font-size: 11px;
                font-weight: 600;
                color: #2E2E2E;
                text-transform: uppercase;
                margin-bottom: 5px;
            }
            input {
                height: 51px;
                border: 1px solid #D9E3E7;
                border-radius: 4px;
                background-color: #FFFFFF;
                box-shadow: none;
                @include form_elements_placeholder();
                &::-webkit-input-placeholder {
                    @include form_elements_placeholder();
                }
                &::-ms-input-placeholder {
                    @include form_elements_placeholder();
                }
                &:-ms-input-placeholder {
                    @include form_elements_placeholder();
                }
                &::-moz-placeholder {
                    @include form_elements_placeholder();
                }
                &:-moz-placeholder {
                    @include form_elements_placeholder();
                }
                &::placeholder {
                    @include form_elements_placeholder();
                }
            }
        }
        &.form_element__textarea {
            .form_element__textarea__wrap {
                position: relative;
                &__controls {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 100%;
                    height: 45px;
                    border-top: solid 1px #D9E3E7;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                    padding: 0 13px;
                    &__icons {
                        display: flex;
                        a {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 31px;
                            height: 31px;
                            border: 1px solid #E8E8E8;
                            border-color: transparent;
                            border-radius: 2px;
                            background-color: transparent;
                            margin-right: 10px;
                            svg {
                                color: #919191;
                            }
                            &.active, &:focus, &:active {
                                background-color: #F8FAFB;
                                border-color: #E8E8E8;
                            }
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            textarea {
                border: 1px solid #D9E3E7;
                border-radius: 4px;
                background-color: #FFFFFF;
                box-shadow: none;
                resize: none;
                width: 100%;
                height: 500px;
                min-height: 0;
                @include form_elements_placeholder();
            }
        }
        &.form_element__datepicker {
            margin-top: 31px;
            > label {
                display: block;
                font-size: 11px;
                font-weight: 600;
                color: #2E2E2E;
                text-transform: uppercase;
                margin-bottom: 5px;
            }
            .form_element__datepicker__wrap {
                position: relative;
                &__icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 56px;
                    height: 51px;
                    border-left: solid 1px #D9E3E7;
                    svg {
                        width: 24px;
                        height: 24px;
                        color: #00A999;
                    }
                }
            }
            .react-datepicker-wrapper {
                display: block;
                .react-datepicker__input-container {
                    > input {
                        height: 51px;
                        width: 100%;
                        border: 1px solid #D9E3E7;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        box-shadow: none;
                        padding: 16px 72px 16px 16px;
                        outline: none !important;
                    }
                }
            }
        }
        &.form_element__radio-inline {
            display: flex;
            margin-bottom: 30px;
            > div {
                margin-right: 8px;
                input {
                    display: none;
                }
                label {
                    position: relative;
                    font-size: 14px;
                    color: #2B2B2B;
                    margin-bottom: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 51px;
                    border: 1px solid #D9E3E7;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    padding-right: 21px;
                    padding-left: 49px;
                    cursor: pointer;
                    .check_round {
                        left: 22px;
                        width: 20px;
                        height: 20px;
                        &__inside {
                            width: 10px;
                            height: 10px;
                            left: calc(50% - 5px);
                            top: calc(50% - 5px);
                        }
                    }
                }
                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
}
