.alert {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: transparent;
    .alert__title {
        margin-bottom: 5px;
    }
    &.alert--error {
        color: rgb(97, 26, 21);
        background-color: rgb(253, 236, 234);
    }
}