.react-confirm-alert {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    max-width: 800px;
    max-height: 600px;
}

.theme_confirm {
    padding: 15px;
    min-width: 300px;
    border: 1px solid #D9E3E7;
    &__cta {
        display:  flex;
        justify-content: space-between;
        &.single {
            justify-content: flex-end;
        }
    }
}

.theme_fullscreen {
    padding: 15px;
    width: 100%;
    border: 1px solid #D9E3E7;  
    .theme_fullscreen__body {
        img {
            cursor: pointer;
            width: 100%;
            max-height: 600px;
        }
    }
    .theme_fullscreen__cta {
        display:  flex;
        justify-content: space-between;
        margin-top: 15px;
        &.single {
            justify-content: flex-end;
        }
        &.top-alig-left {
            margin-top: 0;
            margin-bottom: 15px;
            justify-content: flex-start;
        }
    }
}
