.session_expire_warning {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 20px;
    left: calc(50% - 225px);
    text-align: left;
    font-weight: 600;
    z-index: 15;
    padding: 15px;
    border-radius: 4px;
    color: #fff;
    width: 500px;
    background-color: rgb(199, 84, 79);
}

.flash_message {
    position: fixed;
    display: flex;
    align-items: center;
    top: 20px;
    left: calc(50% - 225px);
    text-align: left;
    font-weight: 600;
    z-index: 10;
    padding: 15px 45px 15px 15px;
    border-radius: 4px;
    color: #fff;
    width: 500px;
    .flash_message__remove {
        position: absolute;
        top: 2px;
        right: 5px;
        z-index: 11;
        color: #fff;
    }
    .flash_message__text {
        padding-left: 32px;
    }
    .flash_message__icon {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: calc(50% - 12.5px);
        left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &--error {
        background-color: rgb(199, 84, 79);
        .flash_message__icon {
            svg {
                display: block;
                width: 16px;
                height: 16px;
                color: rgb(199, 84, 79);
            }
        }
    }
    &--success {
        background-color: rgb(118, 191, 163);
        .flash_message__icon {
            svg {
                display: block;
                width: 16px;
                height: 16px;
                color: rgb(118, 191, 163);
            }
        }
    }
}
