.react-autosuggest {
  &__container {
    input.react-autosuggest__input--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__suggestions-container {
    display: none;

    &--open {
      display: block;
      position: relative;
      top: -14px;
      width: 100%;
      max-height: 200px;
      overflow: auto;
      background-color: var(--white);
      border: 1px solid var(--bg-gray-8);
      border-top: 1px solid var(--bg-gray-8);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 2;
    }
  }

  &__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__suggestion {
    cursor: pointer;
    padding: 12px 24px;

    &--highlighted {
      background-color: var(--bg-hover);
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}