.patient_detail {
    height: 100%;
    .patient_detail__data {
        padding: 27px 32px 0;
        height: calc(100vh - 315px);
        overflow-y: auto;
        overflow-x: hidden;
        .data_protection {
            .data_protection__cnt {
                margin-top: 30px;
            }
        }
        .lab_results {
            .lab_results__header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .lab_results__header__title {
                    p {
                        font-size: 18px;
                        color: #2B2B2B;
                        margin-bottom: 0px;
                    }
                }
                .lab_results__header__control {
                    a {
                        display: flex;
                        align-items: center;
                        height: 40px;
                        padding: 0 24px;
                        font-size: 14px;
                        color: #00A999;
                        border-radius: 24px;
                        background-color: transparent;
                        border: 2px solid #00A999;
                        svg {
                            margin-right: 15px;
                        }
                    }
                }
            }
            .lab_results__dropzone {
                margin-top: 15px;
            }
            .lab_results__body {
                margin-top: 25px;
                .lab_results__body__tbl {
                    &__controls {
                        display: flex;
                        a {
                            margin-right: 27px;
                            &:last-child {
                                margin-right: 0;
                            }
                            svg {
                                display: block;
                                width: 24px;
                                height: 24px;
                                color: #4B4B4B;
                            }
                        }
                    }
                }
            }
        }
        .details {
            display: flex;
            justify-content: space-between;
            .details__left {
                flex: 0 0 50%;
                max-width: 50%;
                padding-right: 15px;
                color: #2B2B2B;
                &__title {
                    font-size: 15px;
                    margin-bottom: 12px;
                    font-weight: 600;
                }
                &__text {
                    font-size: 14px;
                }
            }
            .details__right {
                flex: 0 0 50%;
                max-width: 50%;
                padding-left: 15px;
                .anamneses_card {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 4px;
                    background-color: #fff;
                    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
                    margin-bottom: 10px;
                    .anamneses_card__info {
                        display: flex;
                        align-items: center;
                        flex: 1;
                        padding: 15px 0 15px 15px;
                        cursor: pointer;
                        .anamneses_card__info__icon {
                            margin-right: 20px;
                            cursor: pointer;
                        }
                        .anamneses_card__info__text {
                            p {
                                color: #2B2B2B;
                                margin-bottom: 0;
                                font-size: 14px;
                                font-weight: 600;
                                &:nth-child(1) {
                                    font-size: 12px;
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                    .anamneses_card__cta {
                        flex: 0 0 50px;
                        width: 50px;
                        max-width: 50px;
                        padding: 15px 15px 15px 5px;
                        a {
                            color: #2B2B2B;
                            font-size: 26px;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
    .patient_detail__tabs {
        margin-top: 18px;
    }
}