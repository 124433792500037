@import '../../style/breakpoints.scss';

.Card {
	max-width: 512px;
	padding: 24px;

	background-color: var(--white);
	border: none;
	margin: 0 auto;
	box-shadow: var(--elevation-5);
	border-radius: 3px;

	@media(min-width: $xs-to-sm) {
		margin-top: 128px;
	}
}