:root {
  --font-size-1: 12px;
  --font-size-2: 14px;
  --font-size-3: 16px;
  --font-size-4: 18px;
  --font-size-5: 20px;
  --font-size-6: 24px;
  --font-size-7: 30px;
  --font-size-8: 36px;
  --font-size-9: 48px;
  --font-size-10: 60px;
  --font-size-11: 72px;
  --font-size-12: 86px;

  --main-font-size: var(--font-size-3);
}
