.AddLesson {
  &__initial {
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: var(--bg-hover);
    }
  }

  &__form {
    display: inline-flex;

    &__input {
      position: relative;
      display: flex;
    }

    input {
      border: 1px solid var(--bg-gray-5);
      flex: 1 1 auto;
      display: flex;
      padding: 6px 12px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: none;

      &:focus, &:active {
        outline: 0;
        border-color: var(--bg-gray-8);
      }
    }
  }

  &__submit {
    margin-left: -1px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    border: 1px solid var(--bg-gray-8);
    padding: 0 12px;
    color: var(--white);
    background-color: var(--bg-gray-7);

    &:hover, &:focus {
      background-color: var(--bg-gray-8);
    }

    &:disabled {
      background-color: var(--bg-gray-6);
      &:hover, &:focus {
        cursor: not-allowed;
      }
    }
  }

  &__close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    align-self: center;
    right: 12px;
  }
}