@import 'theme_elements';
@import 'theme_flash';
@import 'theme_confirm';
@import 'theme_alert';
@import 'theme_icons';

.app_bottom_nav_config_content {
    .config_content__misc {
        padding: 10px 20px;
        border-bottom: solid 1px #2E2E2E;
        a { 
            display: block;
            width: 100%;
            color: #2E2E2E;
            text-align: left;
            &.active, &:hover, &:active {
                color: #00A999;
            }
        }
    }
    .config_content__langs {
        padding: 10px 20px;
        a {
            display: block;
            width: 100%;
            color: #2E2E2E;
            text-align: left;
            margin-bottom: 5px;
            &.active, &:hover, &:active {
                color: #00A999;
            }
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
}

.app {
    // display: flex;
    // min-height: 100vh;
    // flex-direction: column;
    @import 'patient_list';
    @import 'patient_panel';
    @import 'patient_form';
    @import 'patient_detail';
    @import 'anamnese_create';
    @import 'anamnese_details';
    @import 'treatment_details';
    @import 'anamnese_form';
    @import 'graph_editor';
    @import 'file_dropzone_modal';
    @import 'home_lessons_editor';
    @import 'basic_upload_button';
    @import 'speech_recognition_theme';
    .app_left_paddings {
        padding: 0 32px;
    }
    .app_top_nav_bar {
        width: 100%;
        height: 64px;
        background-color: #FFFFFF;
        border-bottom: solid 1px rgba(0,0,0,0.1);
        position: fixed;
        z-index: 8;
        width: 100%;
        top: 0;
        &.override {
            z-index: 9;
        }
        .app_top_nav_bar__inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            padding: 0 32px;
            &.app_top_nav_bar__inner--flex-layout {
                .app_top_nav_bar__left-link {
                    flex: 0 0 33.3333333%;
                    max-width: 33.3333333%;
                }
                .app_top_nav_bar__page-title {
                    flex: 0 0 33.3333333%;
                    max-width: 33.3333333%;
                }
                .app_top_nav_bar__right-link {
                    flex: 0 0 33.3333333%;
                    max-width: 33.3333333%;
                    display: flex;
                    justify-content: flex-end;
                }
                .app_top_nav_bar__logo {
                    flex: 0 0 33.3333333%;
                    max-width: 33.3333333%;
                    display: flex;
                    justify-content: flex-start;
                }
                .app_top_nav_bar__auth-info {
                    flex: 0 0 33.3333333%;
                    max-width: 33.3333333%;
                    display: flex;
                    justify-content: flex-end;
                }
            }
            .app_top_nav_bar__left-link {
                a {
                    color: #00A999;
                    font-size: 15px; 
                }
            }
            .app_top_nav_bar__right-link {
                a {
                    color: #00A999;
                    font-size: 15px; 
                }
            }
            .app_top_nav_bar__logo {
                .logo {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .logo__cta {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        background-color: #00A999;
                        margin-right: 12px;
                        border: solid 1px #00A999;
                        img {
                            width: 100%;
                            border-radius: 50%;
                        }
                    }
                    .logo__text {
                        font-size: 14px;
                        font-weight: 600;
                        color: #2E2E2E;
                        text-transform: uppercase;
                    }
                }
            }
            .app_top_nav_bar__page-title {
                color: #2E2E2E;
                font-size: 17px;
                font-weight: 600;
                text-align: center;
                p {
                    color: #2E2E2E;
                    font-size: 17px;
                    font-weight: 600;
                    text-align: center;
                    margin-bottom: 0;
                    &:last-child {
                        font-weight: 400;
                        font-size: 14px;
                        margin-top: 5px;
                    }
                }
            }
            .app_top_nav_bar__auth-info {
                .avatar {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &__image {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        background-color: #00A999;
                        margin-left: 12px;
                    }
                    &__text {
                        color: #2E2E2E;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .app_container {
        position: relative;
        background-color: #f9fafb;
        overflow: hidden;
        padding-top: 64px;
        &__inner {
            position: relative;
            padding: 15px 32px 0;
            height: calc(100vh - 114px);
            &.fullWidth {
                padding: 0;
                // height: calc(100vh - 113px);
            }
            &.customHead {
                padding: 0;
            }
            &.paddingLess {
                padding: 0;
            }
            &.whiteBackground {
                background-color: #fff;
            }
        }
    }
    .app_bottom_nav_bar {
        width: 100%;
        height: 50px;
        background-color: #FFFFFF;
        box-shadow: 0 -1px 0 0 rgba(0,0,0,0.1);
        position: fixed;
        z-index: 10;
        bottom: 0;
        .app_bottom_nav_bar__inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            padding: 0 12px;
            .app_bottom_nav_bar__link {
                color: #757575;
                display: flex;
                flex-direction: column;
                align-items: center;
                span {
                    font-size: 10px;
                }
                &.green_variant {
                    color: #00A999;
                }
                &.hidden {
                    visibility: hidden;
                }
                &:hover, &:active, &:focus {
                    color: #00A999;
                }
            }
        }
    }
}