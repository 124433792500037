.theme_questions {
    .question {
        padding-bottom: 14px;
        margin-bottom: 14px;
        border-bottom: solid 1px #E6EAEC;
        &:last-child {
            border-bottom: none;
            margin-bottom: 0px;
        }
        .question__title {
            position: relative;
            margin-bottom: 12px;
            p {
                font-size: 14px;
                font-weight: 600;
                color: #2B2B2B;
                margin-bottom: 0;
                padding-right: 150px;
            }
            &.question__title--inlarge {
                margin-bottom: 0px;
                p {
                    font-size: 16px;
                }
            }
            .relevance {
                position: absolute;
                top: 0;
                right: 0;
                .Relevance__check {
                    position: relative;
                    width: 18px;
                    height: 18px;
                    border: solid 1px #00A999;
                    margin-right: 6px;
                    &:last-child {
                        margin-right: 0px;
                    }
                    &:before {
                        content: '';
                        display: none;
                        position: absolute;
                        left: 2px;
                        top: 2px;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        background-color: #00A999;
                    }
                    &--checked {
                        background-color: transparent;
                        border-color: #00A999;
                        &:before {
                            display: block;
                        }
                    }
                }
            }
        }
        .question__body {
            position: relative;
            .question__body__wrap {
                position: relative;
                user-select: none;
            }
            .speech_recognition_control {
                position: absolute;
                width: 40px;
                height: 100%;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba( 230, 234, 236, .4);
                cursor: pointer;
                user-select: none;
                &.speech_recognition_control--record {
                    opacity: 1;
                    left: 0;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    border-right: solid 1px #E6EAEC;
                    background-image: url('../assets/img/recording_microphone.png');
                    background-position: center center;
                    background-repeat: no-repeat;
                    &.listening {
                        opacity: .4;
                    }
                    &.not_supported {
                        font-size: 8px;
                        color: red;
                    }
                }
                &.speech_recognition_control--delete {
                    right: 0;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-left: solid 1px #E6EAEC;
                }
            }
            .Answer__optional__form {
                position: relative;
            }
            .Form__input {
                position: relative;
                .Form__textarea__disabled,
                .Form__input__disabled {
                    box-shadow: none;
                    background-color: #fff;
                    border: 1px solid #E6EAEC;
                } 
                input[type='text'] {
                    padding: 0 50px;
                    font-size: 14px;
                    height: 51px;
                    border: 1px solid #E6EAEC;
                    border-radius: 4px;
                    background-color: #fff;
                    box-shadow: none;
                }
                textarea {
                    padding: 16px 50px;
                    font-size: 14px;
                    border: 1px solid #E6EAEC;
                    border-radius: 4px;
                    background-color: #fff;
                    box-shadow: none;
                    resize: none;
                }
            }
            .space_holder {
                width: 100%;
                height: 18px;
                margin-bottom: 12px;
            }
        }
    }
}
