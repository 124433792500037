.Files {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  &__empty {
    height: 60%;
    width: 100%;
  }

  &__offline {
    height: 60%;
    width: 100%;
  }

  &__list {
    height: auto;
    padding: 32px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &__upload {
    width: 100%;
  }
  //Copies from Dropzones.scss
  &__file {
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 18px 0;
    margin: 0 8px;

    width: 120px;
    color: var(--text-gray-4);

    &__name {
      display: block;
      width: 100%;
      margin-top: 4px;
      word-break: break-all;
      line-height: 1.25em;
      text-align: center;
    }

    &__icon {
      font-size: 48px;
    }

    &__delete {
      z-index: 3;
      position: absolute;
      cursor: pointer;
      width: 24px;
      height: 24px;
      background-color: var(--white);
      color: var(--bg-gray-7);
      border: 2px solid var(--bg-gray-5);
      border-radius: 50%;
      left: calc(50% + 18px);
      top: 0px;
      text-align: center;
      align-items: center;
      display: block;
  
      svg {
        font-size: 24px;
        position: absolute;
        left: -2px;
        top: -2px;
      }
  
      &:hover {
        color: var(--bg-gray-9);
        border-color: var(--bg-gray-9);
      }
  
      &:focus, &:active {
        outline: none;
        color: var(--bg-gray-9);
        border-color: var(--bg-gray-9);
      }
    }
  }
}