@import '../../style/breakpoints.scss';

.PatientDetail {
  --v-padding: 24px;

  @media (min-width: $sm-to-md) {
    --v-padding: 32px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding-bottom: var(--v-padding);
    border-bottom: 4px solid var(--bg-gray-5);
    align-items: center;
    text-align: center;

    @media (min-width: $sm-to-md) {
      text-align: left;
      flex-direction: row;
    }

    &__overview {
      display: flex;
      flex-direction: column;
      font-size: var(--font-size-4);

      .bold {
        font-size: var(--font-size-7);
        font-weight: 600;
      }
    }

    &__actions {
      --icon-width: 140px;

      display: flex;
      flex-direction: row;
      margin-top: 24px;

      @media (min-width: $sm-to-md) {
        --icon-width: 100px;
        margin-top: 0;
        margin-left: auto;
      }

      .lab-results--offline {
        cursor: not-allowed;
        color: var(--button-color-disabled);
        &:hover, &:focus {
          color: var(--button-color-disabled);
        }
      }

      a {
        display: flex;
        flex-direction: column;
        font-size: var(--font-size-3); 
        color: var(--button-color);
        max-width: var(--icon-width);
        padding: 0 8px;
        align-items: center;
        text-align: center;

        svg {
          margin-bottom: 4px;
          font-size: var(--font-size-9);
        }

        &:hover, &:focus {
          color: var(--button-color-hover);
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: var(--v-padding) 0;
    height: auto;

    @media (min-width: $sm-to-md) {
      flex-direction: row;
      height: 580px;
    }

    &__title {
      font-size: var(--font-size-6);
      font-weight: 600;
      margin-bottom: 12px;
      flex: 0 0 auto;
    }

    &__inner {
      border: 2px solid var(--bg-gray-5);
      border-radius: 4px;
      padding: 24px;
      flex: 1 1 auto;

      @media (min-width: $sm-to-md) {
        height: 0;
        min-height: 0;
      }
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__no-description {
      position: relative;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__anamnesis {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    flex: 1;

    @media (min-width: $sm-to-md) {
      margin-top: 0;
      margin-left: 32px;
    }

    &__list {
      margin: 0;
      padding: 0;
      height: 100%;
      overflow: auto;
      list-style-type: none;
    }

    &__item {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      text-align: center;
      cursor: pointer;
      padding: 16px;
      font-size: var(--font-size-5);

      svg {
        margin-right: 16px;
        font-size: var(--font-size-7);
      }

      &:first-child {
        padding-top: 24px;
      }

      &:hover, &:focus {
        background-color: var(--bg-hover);
      }
    }
  }
}