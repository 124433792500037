.PatientForm {
  width: 100%;

  &__text-area {
    flex: 1 1 auto;
  }
  
  &__privacy-checkbox {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 16px;

    &__link {
      font-size: 48px;
      padding-right: 8px;
      color: var(--text-gray-5);
    }

    &__checkbox {
      padding-left: 8px;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      input {
        display: block;
        width: 100%;
        background-color: var(--bg-gray-1);
        border: 2px solid var(--bg-gray-5);
        border-radius: 4px;
        box-shadow: inset 0 2px 2px hsla(0, 0%, 0%, 0.1), inset 0 -2px 0 hsla(0, 0%, 100%, 0.2);
        margin: var(--item-margin-v) 0;
        font-weight: normal;
        height: 100%;
        padding: 16px;
        font-size: 1.25rem;
        outline: 0;
        position: relative;
      }
      
      input:focus {
        border-color: var(--bg-gray-8);
      }
    }
  }
}