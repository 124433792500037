.LoadingDots {
  --margin-top: 164px;

  width: 100%;
  font-size: 20px;
  color: var(--primary-color);
  margin-top: var(--margin-top);
  text-align: center;
  z-index: 5;

  > svg {
    display: inline;
    margin: 0 8px;
    opacity: 0;
    animation: showHideDot 1s ease-in-out infinite;

    &.one { animation-delay: 0.05s; }
    &.two { animation-delay: 0.1s; }
    &.three { animation-delay: 0.2s; }
  }
}

@keyframes showHideDot {
  0% { opacity: 0; }
  50% { opacity: 1; }
  60% { opacity: 1; }
  100% { opacity: 0; }
}