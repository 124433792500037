@import '../../style/breakpoints.scss';

.FileDropzone {
  --border-width: 4px;
  --border-color: var(--bg-gray-6);
  --text-color: var(--bg-gray-6);

  --transition-duration: 0.2s;

  display: flex;
  flex-direction: column;
  cursor: pointer;

  height: 100%;
  padding: 32px;
  line-height: 2em;

  border: var(--border-width) dashed var(--border-color);
  border-radius: 10px;

  transition:
    background-color var(--transition-duration),
    border-color var(--transition-duration),
    color var(--transition-duration);

  &:hover, &:focus {
    outline: none;
    --text-color: var(--bg-gray-7);
    --border-color: var(--bg-gray-7);
  }

  &__loading-dots {
    margin: 0;
    align-self: center;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__files {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 16px;
    max-height: 296px;
    overflow: auto;

    @media(min-width: $sm-to-md) {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }

  &__file {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 18px 0;
    margin: 0 8px;
    width: 120px;
    color: var(--text-gray-4);

    &__name {
      display: block;
      width: 100%;
      margin-top: 4px;
      word-break: break-all;
      line-height: 1.25em;
      text-align: center;
    }

    &__icon {
      font-size: 48px;
    }

    &__delete {
      z-index: 3;
      position: absolute;
      cursor: pointer;
      width: 24px;
      height: 24px;
      background-color: var(--white);
      color: var(--bg-gray-7);
      border: 2px solid var(--bg-gray-5);
      border-radius: 50%;
      left: calc(50% + 18px);
      top: 0px;
      text-align: center;
      align-items: center;
      display: block;
  
      svg {
        font-size: 24px;
        position: absolute;
        left: -2px;
        top: -2px;
      }
  
      &:hover {
        color: var(--bg-gray-9);
        border-color: var(--bg-gray-9);
      }
  
      &:focus, &:active {
        outline: none;
        color: var(--bg-gray-9);
        border-color: var(--bg-gray-9);
      }
    }
  }

  &__active {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media(min-width: $sm-to-md) {
      flex-direction: row;
    }
  }

  &__submit {
    flex: 0 1 auto;
    margin: auto;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 1.25em;
    padding: 18px;

    svg {
      margin-right: 8px;
    }
  }
  &__text {
    text-align: center;
    font-weight: bold;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: var(--text-color);
  }
}
