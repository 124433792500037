.home_lessons_editor {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 25px;
    &.active {
        display: block;
    }
    .home_lessons_edItor__searchbar {
        display: flex;
        margin-bottom: 15px;
        .home_lessons_editor__searchbar__search {
            .theme_text_input {
                padding: 16px 48px 16px 48px;
            }
        }
        .home_lessons_edItor__searchbar__cnt {
            height: 51px;
            border-radius: 4px;
            margin-left: 15px;
        }
        .Search__input__close {
            right: 50px;
        }
    }
    .home_lessons_edItor__groups {
        max-height: 300px;
        overflow-y: auto;
        .groups_list {
            padding: 0;
            margin: 0;
            list-style: none;
            .groups_list__category {
                margin-bottom: 25px;
                .groups_list__category__title {
                    display: block;
                    margin-bottom: 10px;
                }
                .groups_list__category__lesson {
                    .theme_checkbox {
                        label {
                            font-size: 14px;
                            font-weight: 400;
                            user-select: none;
                        }
                    }
                }
                .groups_list__category__title__add-lesson {
                    .add_lesson_cta {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        color: #757575;
                        margin-top: 10px;
                        &__icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 22px;
                            height: 22px;
                            border-radius: 50%;
                            border: solid 1px #00A999;
                            padding: 5px;
                            color: #00A999;
                            margin-right: 10px;
                        }
                    }
                    .AddLesson__form {
                        .AddLesson__form__input {
                            .theme_text_input {
                                height: 40px !important;
                                border-top-right-radius: 0px !important;
                                border-bottom-right-radius: 0px !important;
                            }
                        }
                        .AddLesson__submit {
                            border-color: #00A999;
                            background-color: #00A999;
                            opacity: 1;
                            &:disabled {
                                opacity: .7;
                            }
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
