.HomeLessons {
  padding: 12px 16px;

  ul {
    list-style: none;
    padding: 0;
  }

  &__category {
    margin-bottom: 12px;

    &__title {
      font-weight: 500;
    }
  }
}