.SignUpForm {
  &__subtitle {
    text-align: center;
    margin-bottom: 16px;
    margin-top: -8px;
    width: 100%;
    font-size: 1.25em;
    font-weight: 500;
  }

  &__confirm {
    display: flex;
    flex-direction: column;
    text-align: center;

    span {
      margin: 16px;
      font-weight: 500;
    }

    &__description {
      font-size: 1.25em;
      color: var(--text-gray-5);
    }

    &__key {
      color: var(--text-gray-4);
      font-size: 1em;
      word-break: break-all;
    }

    &__button {
      width: 100%;
      margin-top: 16px;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 1.2em;
      font-weight: 600;
      color: var(--white);
    }
  }
}