@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css?family=Tangerine");
@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(10px); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  from {
    transform: translateY(10px); }
  to {
    transform: translateY(0); } }

:root {
  --white: hsl(0, 0%, 100%);
  --white-hover: hsl(0, 0%, 80%);
  --text-gray-1: hsl(0, 0%, 97%);
  --text-gray-2: hsl(0, 0%, 85%);
  --text-gray-3: hsl(0, 0%, 65%);
  --text-gray-4: hsl(0, 0%, 50%);
  --text-gray-5: hsl(0, 0%, 40%);
  --text-gray-6: hsl(0, 0%, 20%);
  --bg-gray-1: hsl(210, 14%, 97.7%);
  --bg-gray-2: hsl(210, 10.1%, 94.7%);
  --bg-gray-3: hsl(210, 10%, 92.3%);
  --bg-gray-4: hsl(210, 10%, 85.6%);
  --bg-gray-5: hsl(210, 9.8%, 80.1%);
  --bg-gray-6: hsl(210, 10.9%, 70.9%);
  --bg-gray-7: hsl(210, 10.9%, 60.9%);
  --bg-gray-8: hsl(210, 10.9%, 50.9%);
  --bg-gray-9: hsl(210, 10.9%, 40.9%);
  --gray: #bbb;
  --gray-darker: #aaa;
  --primary-color: hsl(200.9, 100%, 50.1%);
  --primary-color-hover: hsl(200, 100%, 40%);
  --button-color: hsl(200, 100%, 30%);
  --button-color-hover: hsl(200, 100%, 15%);
  --button-color-disabled: hsl(200, 40%, 60%);
  --bg-hover: var(--bg-gray-2);
  --link-color: lightslategray;
  --link-color-hover: #9ca8b4;
  --green: rgb(45, 194, 26);
  --green-darker: rgb(42, 153, 28);
  --red: rgb(221, 53, 53);
  --red-darker: #861212;
  --navbar-bg-color: var(--bg-gray-2);
  --main-bg-color: hsl(200, 50%, 85%);
  --main-text-color: var(--text-gray-5);
  --success-color: var(--green);
  --error-color: var(--red); }

:root {
  --elevation-1: 0 1px 3px hsla(0, 0%, 0%, .2);
  --elevation-2: 0 4px 6px hsla(0, 0%, 0%, .2);
  --elevation-3: 0 5px 9px hsla(0, 0%, 0%, .2);
  --elevation-4: 0 10px 12px hsla(0, 0%, 0%, .2);
  --elevation-5: 0 15px 18px hsla(0, 0%, 0%, .2); }

.EmptyPlaceholder {
  display: block;
  margin: 32px auto;
  text-align: center;
  font-size: var(--font-size-6);
  color: var(--text-gray-3); }
  @media (min-width: 800px) {
    .EmptyPlaceholder {
      position: relative;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%); } }
  .EmptyPlaceholder a {
    color: var(--primary-color); }
    .EmptyPlaceholder a:hover, .EmptyPlaceholder a:focus, .EmptyPlaceholder a:active {
      color: var(--primary-color-hover); }

.SplitLayout {
  --split-layout-gap: 32px;
  flex: 1;
  display: flex;
  flex-direction: column; }
  @media (min-width: 800px) {
    .SplitLayout {
      flex-direction: row; } }
  .SplitLayout--static {
    flex-direction: row; }
  .SplitLayout__left {
    height: 100%;
    flex: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column; }
  .SplitLayout__right {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: var(--split-layout-gap); }
    @media (min-width: 800px) {
      .SplitLayout__right {
        margin-top: 0;
        margin-left: var(--split-layout-gap); } }
  .SplitLayout__pull-right {
    flex: 0 1 auto; }

:root {
  --font-size-1: 12px;
  --font-size-2: 14px;
  --font-size-3: 16px;
  --font-size-4: 18px;
  --font-size-5: 20px;
  --font-size-6: 24px;
  --font-size-7: 30px;
  --font-size-8: 36px;
  --font-size-9: 48px;
  --font-size-10: 60px;
  --font-size-11: 72px;
  --font-size-12: 86px;
  --main-font-size: var(--font-size-3); }

:root {
  --navbar-index: 100;
  --dropzone-overlay-index: 100;
  --flash-index: 200;
  --offline-banner-index: 150;
  --subnav-index: 150; }

:root {
  --border-radius: 3px;
  --max-width: 1200px;
  --padding-sides: 20px; }

* {
  box-sizing: border-box; }

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%; }

html, body, #root, .app, .app_container__inner {
  overflow: hidden; }

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto Oxygen-Sans, Ubuntu, Cantarell, “Fira Sans”, “Droid Sans”, "Helvetica Neue", Helvetica, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal;
  color: var(--main-text-color);
  font-size: var(--main-font-size); }

input, button {
  font: inherit;
  color: inherit;
  border: none; }

button {
  padding: 0;
  font: inherit;
  border: none;
  cursor: pointer;
  background-color: transparent; }
  button:active, button:focus {
    outline: none; }

a {
  text-decoration: none; }
  a:visited, a:hover, a:active {
    text-decoration: none; }

.auth_layout {
  position: relative;
  height: 100%;
  min-height: 100%; }
  .auth_layout .auth_nav_bar {
    position: absolute;
    left: 22px;
    top: 34px;
    z-index: 1; }
    .auth_layout .auth_nav_bar .logo {
      display: flex;
      justify-content: center;
      align-items: center; }
      .auth_layout .auth_nav_bar .logo .logo__cta {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 12px; }
        .auth_layout .auth_nav_bar .logo .logo__cta img {
          width: 100%;
          border-radius: 50%; }
      .auth_layout .auth_nav_bar .logo .logo__text {
        font-size: 16px;
        font-weight: 600;
        color: #fff; }
  .auth_layout .auth_layout_container {
    display: flex;
    height: 100%;
    min-height: 100%; }
    .auth_layout .auth_layout_container .auth_layout__left-bar {
      position: relative;
      max-width: 175px;
      flex: 0 0 175px;
      background-color: rgba(0, 169, 153, 0.6);
      background-image: url("../assets/img/jesper-aggergaard-CEM52sAHR80-unsplash.jpg") !important;
      background-position: -812px 0; }
      @media (min-width: 1020px) {
        .auth_layout .auth_layout_container .auth_layout__left-bar {
          max-width: 450px;
          flex: 0 0 450px; } }
      .auth_layout .auth_layout_container .auth_layout__left-bar .auth_layout__left-bar__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 169, 153, 0.6); }
    .auth_layout .auth_layout_container .auth_layout__content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 100;
      background-color: #F8FAFB; }
      .auth_layout .auth_layout_container .auth_layout__content .auth_form {
        max-width: 350px;
        width: 350px; }
        .auth_layout .auth_layout_container .auth_layout__content .auth_form.auth_form--wide {
          max-width: 550px;
          width: 550px; }
        .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__head {
          font-size: 20px;
          font-weight: 600;
          color: #2B2B2B;
          margin-bottom: 37px; }
        .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input {
          margin-top: 0;
          margin-bottom: 23px; }
          .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input .Form__input__label {
            font-size: 11px;
            color: #2E2E2E;
            font-weight: 600;
            margin-bottom: 5px; }
          .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input input[type='text'],
          .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input input[type='password'] {
            margin: 0;
            box-shadow: none;
            border: 1px solid #D9E3E7;
            background-color: #fff;
            height: 51px;
            opacity: 1;
            font-size: 14px;
            color: #97A4B1; }
            .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input input[type='text']::-webkit-input-placeholder,
            .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input input[type='password']::-webkit-input-placeholder {
              opacity: 1;
              font-size: 14px;
              color: #97A4B1; }
            .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input input[type='text']::-ms-input-placeholder,
            .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input input[type='password']::-ms-input-placeholder {
              opacity: 1;
              font-size: 14px;
              color: #97A4B1; }
            .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input input[type='text']:-ms-input-placeholder,
            .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input input[type='password']:-ms-input-placeholder {
              opacity: 1;
              font-size: 14px;
              color: #97A4B1; }
            .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input input[type='text']::-moz-placeholder,
            .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input input[type='password']::-moz-placeholder {
              opacity: 1;
              font-size: 14px;
              color: #97A4B1; }
            .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input input[type='text']:-moz-placeholder,
            .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input input[type='password']:-moz-placeholder {
              opacity: 1;
              font-size: 14px;
              color: #97A4B1; }
            .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input input[type='text']::placeholder,
            .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__element .Form__input input[type='password']::placeholder {
              opacity: 1;
              font-size: 14px;
              color: #97A4B1; }
        .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__cta button, .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__cta a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 50px;
          border-radius: 25px;
          color: #fff;
          background-color: #00A999;
          font-size: 16px;
          font-weight: 600; }
        .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__cta.flex {
          display: flex;
          justify-content: space-between; }
          .auth_layout .auth_layout_container .auth_layout__content .auth_form .auth_form__body .form .auth_form__body__cta.flex > * {
            width: 48%; }

.error_panel {
  padding: 0 15px; }
  .error_panel .error_panel__inner {
    color: #fff;
    text-align: center;
    background-color: red;
    padding: 15px 0;
    border-radius: 4px; }

.theme_empty_data {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: 600;
  padding: 20px 10px;
  margin-top: 25px; }

.theme_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 24px;
  font-size: 14px;
  font-weight: 500;
  color: #fff !important;
  border-radius: 24px;
  background-color: #00A999;
  border: solid 1px #00A999; }
  .theme_btn--inline {
    display: inline-flex; }
  .theme_btn.disabled {
    opacity: .6; }
  .theme_btn.small {
    height: 32px;
    font-size: 14px; }
  .theme_btn.medium {
    height: 48px;
    font-size: 16px; }
  .theme_btn svg, .theme_btn img {
    margin-right: 15px; }
  .theme_btn:hover, .theme_btn:focus, .theme_btn:active {
    color: #fff; }
  .theme_btn.theme_btn--white {
    color: #2E2E2E !important;
    background-color: transparent;
    border-color: #2E2E2E; }
    .theme_btn.theme_btn--white:hover, .theme_btn.theme_btn--white:focus, .theme_btn.theme_btn--white:active {
      color: #fff !important;
      background-color: #00A999;
      border-color: #00A999; }
  .theme_btn.theme_btn--white-green {
    color: #00A999 !important;
    background-color: #fff;
    border-color: #00A999; }
    .theme_btn.theme_btn--white-green:hover, .theme_btn.theme_btn--white-green:focus, .theme_btn.theme_btn--white-green:active {
      color: #fff !important;
      background-color: #00A999;
      border-color: #00A999; }

.app .hidden_content {
  display: none; }

.app .sympthoms_slide .sympthoms_slide__body {
  height: 100%; }
  .app .sympthoms_slide .sympthoms_slide__body .graph_panel_sheet {
    position: relative;
    width: 100%;
    height: 419px;
    border: 1px solid #E6EAEC;
    border-radius: 3px;
    background-color: #fff;
    margin-bottom: 10px; }
    .app .sympthoms_slide .sympthoms_slide__body .graph_panel_sheet .full_mode {
      position: absolute;
      z-index: 2;
      right: 5px;
      bottom: 5px;
      height: 31px;
      width: 31px;
      border-radius: 2px;
      background-color: #00A999;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center; }
  .app .sympthoms_slide .sympthoms_slide__body .graph_panel_data {
    position: relative;
    height: 100px;
    width: 100%;
    border: 1px solid #E8E8E8;
    border-radius: 3px;
    background-color: #FBF9F8;
    padding: 15px;
    overflow-y: auto; }
    .app .sympthoms_slide .sympthoms_slide__body .graph_panel_data__list {
      padding: 0;
      margin: 0;
      list-style-position: inside; }
      .app .sympthoms_slide .sympthoms_slide__body .graph_panel_data__list li {
        color: #4B4B4B;
        font-size: 14px;
        word-break: break-all; }

.app .theme_relevance {
  display: flex;
  justify-content: space-between; }
  .app .theme_relevance .theme_relevance__check {
    position: relative;
    height: 20px;
    width: 20px;
    border: 1px solid #00A999;
    background-color: #F8FAFB;
    border-radius: 50%;
    margin-right: 5px; }
    .app .theme_relevance .theme_relevance__check--checked:before {
      content: '';
      position: absolute;
      left: 3px;
      top: 3px;
      width: 12px;
      height: 12px;
      background-color: #00A999;
      border-radius: 50%; }
    .app .theme_relevance .theme_relevance__check--clickable {
      cursor: pointer; }
    .app .theme_relevance .theme_relevance__check:last-child {
      margin-right: 0px; }

.app .theme_header {
  background-color: #F8FAFB;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
  padding: 24px 32px 0; }
  .app .theme_header .theme_header__info {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .app .theme_header .theme_header__info .theme_header__info__left {
      flex: 0 0 50%;
      max-width: 50%; }
      .app .theme_header .theme_header__info .theme_header__info__left .info_cell {
        display: flex;
        align-items: center; }
        .app .theme_header .theme_header__info .theme_header__info__left .info_cell__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
          margin-right: 12px;
          background-color: #fff; }
        .app .theme_header .theme_header__info .theme_header__info__left .info_cell__text p {
          font-size: 13px;
          color: #2B2B2B;
          margin-bottom: 0; }
          .app .theme_header .theme_header__info .theme_header__info__left .info_cell__text p:nth-child(1) {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 5px; }
    .app .theme_header .theme_header__info .theme_header__info__right {
      flex: 0 0 50%;
      max-width: 50%; }
      .app .theme_header .theme_header__info .theme_header__info__right .control_cell {
        display: flex;
        justify-content: flex-end; }
        .app .theme_header .theme_header__info .theme_header__info__right .control_cell__icon {
          margin-right: 12px; }
          .app .theme_header .theme_header__info .theme_header__info__right .control_cell__icon a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: solid 1px #2E2E2E;
            transition: none; }
            .app .theme_header .theme_header__info .theme_header__info__right .control_cell__icon a svg {
              width: 24px;
              height: 24px;
              color: #2E2E2E; }
            .app .theme_header .theme_header__info .theme_header__info__right .control_cell__icon a:hover, .app .theme_header .theme_header__info .theme_header__info__right .control_cell__icon a.active {
              border-color: #00A999; }
              .app .theme_header .theme_header__info .theme_header__info__right .control_cell__icon a:hover svg, .app .theme_header .theme_header__info .theme_header__info__right .control_cell__icon a.active svg {
                color: #00A999; }
        .app .theme_header .theme_header__info .theme_header__info__right .control_cell__btn a {
          display: flex;
          align-items: center;
          height: 48px;
          padding: 0 24px;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          border-radius: 24px;
          background-color: #00A999; }
    .app .theme_header .theme_header__info.full-width {
      justify-content: flex-end; }
      .app .theme_header .theme_header__info.full-width .theme_header__info__right {
        flex: 0 0 100%;
        max-width: 100%; }

.app .theme_radio_group {
  display: flex; }
  .app .theme_radio_group.mb_15 {
    margin-bottom: 15px; }
  .app .theme_radio_group .theme_radio {
    margin-right: 15px; }
    .app .theme_radio_group .theme_radio:last-child {
      margin-right: 0px; }

.app .theme_radio input {
  display: none; }

.app .theme_radio label {
  position: relative;
  font-size: 14px;
  color: #2B2B2B;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-left: 26px; }
  .app .theme_radio label .check_round {
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #757575;
    background-color: #fff; }
    .app .theme_radio label .check_round__inside {
      opacity: 0;
      position: absolute;
      top: calc(50% - 6px);
      left: calc(50% - 6px);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #00A999; }
  .app .theme_radio label::first-letter {
    text-transform: lowercase; }

.app .theme_radio input:checked + label .check_round__inside {
  opacity: 1; }

.app .theme_radio input:checked + label::before .check_round {
  border-color: #00A999; }

.app .theme_radio__small label {
  justify-content: left;
  padding-left: 22px;
  font-size: 13px; }
  .app .theme_radio__small label .check_round {
    width: 16px;
    height: 16px; }
    .app .theme_radio__small label .check_round__inside {
      left: calc(50% - 4px);
      top: calc(50% - 4px);
      width: 8px;
      height: 8px; }

.app .theme_checkbox_xl input {
  display: none; }

.app .theme_checkbox_xl label {
  position: relative;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 46px;
  color: #2B2B2B;
  font-size: 18px;
  font-weight: 600; }
  .app .theme_checkbox_xl label::before {
    content: "";
    position: absolute;
    left: 0;
    width: 34px;
    height: 34px;
    border-radius: 4px;
    border: 1px solid #D9E3E7;
    background-color: #fff; }
  .app .theme_checkbox_xl label::after {
    content: "";
    opacity: 0;
    position: absolute;
    left: 11px;
    top: 3px;
    width: 10px;
    height: 16px;
    transform: rotate(45deg);
    border: solid #000;
    border-width: 0 2px 2px 0; }

.app .theme_checkbox_xl input:checked + label::after {
  opacity: 1;
  border-color: #00A999; }

.app .theme_checkbox_xl input:checked + label::before {
  border-color: #00A999; }

.app .theme_checkbox input {
  display: none; }

.app .theme_checkbox label {
  position: relative;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 30px;
  color: #2B2B2B;
  font-size: 14px;
  font-weight: 400; }
  .app .theme_checkbox label::before {
    content: "";
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #D9E3E7;
    background-color: #fff; }
  .app .theme_checkbox label::after {
    content: "";
    opacity: 0;
    position: absolute;
    left: 8px;
    top: 7px;
    width: 6px;
    height: 10px;
    transform: rotate(45deg);
    border: solid #000;
    border-width: 0 2px 2px 0; }

.app .theme_checkbox input:checked + label::after {
  opacity: 1;
  border-color: #00A999; }

.app .theme_checkbox input:checked + label::before {
  border-color: #00A999; }

.app .theme_tabs {
  display: flex;
  margin-top: 25px;
  margin-bottom: 27px;
  box-shadow: inset 0 -1px 0 0 #DEE0E1; }
  .app .theme_tabs a {
    position: relative;
    display: flex;
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 13px;
    font-weight: 500;
    color: #2E2E2E; }
    .app .theme_tabs a.active, .app .theme_tabs a:hover {
      color: #00A999; }
      .app .theme_tabs a.active:after, .app .theme_tabs a:hover:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #00A999;
        width: 100%;
        height: 3px; }

.app .theme_table .table thead tr {
  border-bottom: none; }

.app .theme_table .table tbody tr {
  border-bottom: none; }
  .app .theme_table .table tbody tr td {
    vertical-align: baseline; }
    .app .theme_table .table tbody tr td .theme_table_bold {
      font-weight: 600; }
    .app .theme_table .table tbody tr td .theme_relevance {
      width: 120px; }
    .app .theme_table .table tbody tr td .theme_table__controls {
      display: flex; }
      .app .theme_table .table tbody tr td .theme_table__controls a {
        margin-right: 27px; }
        .app .theme_table .table tbody tr td .theme_table__controls a:last-child {
          margin-right: 0; }
        .app .theme_table .table tbody tr td .theme_table__controls a svg {
          display: block;
          width: 24px;
          height: 24px;
          color: #4B4B4B; }
  .app .theme_table .table tbody tr:nth-child(odd) {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px; }
    .app .theme_table .table tbody tr:nth-child(odd) td {
      background-color: #fff; }

.app .theme_text_input {
  height: 51px !important;
  border: 1px solid #E6EAEC !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  box-shadow: none !important;
  opacity: 1;
  font-size: 14px !important;
  color: #2E2E2E !important; }
  .app .theme_text_input::-webkit-input-placeholder {
    opacity: 1;
    font-size: 14px !important;
    color: #2E2E2E !important; }
  .app .theme_text_input::-ms-input-placeholder {
    opacity: 1;
    font-size: 14px !important;
    color: #2E2E2E !important; }
  .app .theme_text_input:-ms-input-placeholder {
    opacity: 1;
    font-size: 14px !important;
    color: #2E2E2E !important; }
  .app .theme_text_input::-moz-placeholder {
    opacity: 1;
    font-size: 14px !important;
    color: #2E2E2E !important; }
  .app .theme_text_input:-moz-placeholder {
    opacity: 1;
    font-size: 14px !important;
    color: #2E2E2E !important; }
  .app .theme_text_input::placeholder {
    opacity: 1;
    font-size: 14px !important;
    color: #2E2E2E !important; }

.session_expire_warning {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 20px;
  left: calc(50% - 225px);
  text-align: left;
  font-weight: 600;
  z-index: 15;
  padding: 15px;
  border-radius: 4px;
  color: #fff;
  width: 500px;
  background-color: #c7544f; }

.flash_message {
  position: fixed;
  display: flex;
  align-items: center;
  top: 20px;
  left: calc(50% - 225px);
  text-align: left;
  font-weight: 600;
  z-index: 10;
  padding: 15px 45px 15px 15px;
  border-radius: 4px;
  color: #fff;
  width: 500px; }
  .flash_message .flash_message__remove {
    position: absolute;
    top: 2px;
    right: 5px;
    z-index: 11;
    color: #fff; }
  .flash_message .flash_message__text {
    padding-left: 32px; }
  .flash_message .flash_message__icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: calc(50% - 12.5px);
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .flash_message--error {
    background-color: #c7544f; }
    .flash_message--error .flash_message__icon svg {
      display: block;
      width: 16px;
      height: 16px;
      color: #c7544f; }
  .flash_message--success {
    background-color: #76bfa3; }
    .flash_message--success .flash_message__icon svg {
      display: block;
      width: 16px;
      height: 16px;
      color: #76bfa3; }

.react-confirm-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  max-width: 800px;
  max-height: 600px; }

.theme_confirm {
  padding: 15px;
  min-width: 300px;
  border: 1px solid #D9E3E7; }
  .theme_confirm__cta {
    display: flex;
    justify-content: space-between; }
    .theme_confirm__cta.single {
      justify-content: flex-end; }

.theme_fullscreen {
  padding: 15px;
  width: 100%;
  border: 1px solid #D9E3E7; }
  .theme_fullscreen .theme_fullscreen__body img {
    cursor: pointer;
    width: 100%;
    max-height: 600px; }
  .theme_fullscreen .theme_fullscreen__cta {
    display: flex;
    justify-content: space-between;
    margin-top: 15px; }
    .theme_fullscreen .theme_fullscreen__cta.single {
      justify-content: flex-end; }
    .theme_fullscreen .theme_fullscreen__cta.top-alig-left {
      margin-top: 0;
      margin-bottom: 15px;
      justify-content: flex-start; }

.alert {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  border-radius: 4px;
  background-color: transparent; }
  .alert .alert__title {
    margin-bottom: 5px; }
  .alert.alert--error {
    color: #611a15;
    background-color: #fdecea; }

.icon-enabled-link {
  display: flex;
  align-items: center; }
  .icon-enabled-link .icon {
    margin-right: 5px; }

.icon {
  display: block;
  position: relative; }
  .icon.icon-navi-left {
    width: 7px;
    height: 7px; }
    .icon.icon-navi-left:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      transform: rotate(135deg);
      width: 7px;
      height: 7px;
      border-color: #00A999;
      border-style: solid;
      border-width: 0 1px 1px 0; }

.app_bottom_nav_config_content .config_content__misc {
  padding: 10px 20px;
  border-bottom: solid 1px #2E2E2E; }
  .app_bottom_nav_config_content .config_content__misc a {
    display: block;
    width: 100%;
    color: #2E2E2E;
    text-align: left; }
    .app_bottom_nav_config_content .config_content__misc a.active, .app_bottom_nav_config_content .config_content__misc a:hover, .app_bottom_nav_config_content .config_content__misc a:active {
      color: #00A999; }

.app_bottom_nav_config_content .config_content__langs {
  padding: 10px 20px; }
  .app_bottom_nav_config_content .config_content__langs a {
    display: block;
    width: 100%;
    color: #2E2E2E;
    text-align: left;
    margin-bottom: 5px; }
    .app_bottom_nav_config_content .config_content__langs a.active, .app_bottom_nav_config_content .config_content__langs a:hover, .app_bottom_nav_config_content .config_content__langs a:active {
      color: #00A999; }
    .app_bottom_nav_config_content .config_content__langs a:last-child {
      margin-bottom: 0px; }

.app .patient_list .patient_list__tabs {
  margin-top: 25px;
  margin-bottom: 27px; }

.app .patient_list .patient_list__table {
  height: calc(100vh - 310px);
  overflow-y: auto;
  overflow-x: hidden; }

.app .Search {
  overflow: visible !important; }

.app .patient_search {
  position: relative; }
  .app .patient_search .patient_search__suggestions {
    position: absolute;
    left: 0;
    top: 100%;
    width: 351px;
    border: 1px solid #D9E3E7;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
    background-color: #fff;
    font-size: 16px;
    color: #97A4B1;
    z-index: 1; }
    .app .patient_search .patient_search__suggestions__list {
      list-style: none;
      margin: 0;
      padding: 10px 25px; }
      .app .patient_search .patient_search__suggestions__list li {
        margin-bottom: 5px; }
        .app .patient_search .patient_search__suggestions__list li a {
          opacity: 1;
          font-size: 16px;
          color: #97A4B1; }
        .app .patient_search .patient_search__suggestions__list li:last-child {
          margin-bottom: 0px; }

.app .patient_panel {
  display: flex;
  justify-content: space-between; }
  .app .patient_panel__left {
    flex: 0 0 50%;
    max-width: 50%; }
    .app .patient_panel__left .Search {
      width: 351px; }
      .app .patient_panel__left .Search .Search__input {
        height: 49px;
        width: 100%;
        border: 1px solid #D9E3E7;
        border-radius: 24px;
        background-color: #FFFFFF;
        box-shadow: none;
        padding: 0 18px 0 25px;
        opacity: 1;
        font-size: 16px;
        color: #97A4B1; }
        .app .patient_panel__left .Search .Search__input::-webkit-input-placeholder {
          opacity: 1;
          font-size: 16px;
          color: #97A4B1; }
        .app .patient_panel__left .Search .Search__input::-ms-input-placeholder {
          opacity: 1;
          font-size: 16px;
          color: #97A4B1; }
        .app .patient_panel__left .Search .Search__input:-ms-input-placeholder {
          opacity: 1;
          font-size: 16px;
          color: #97A4B1; }
        .app .patient_panel__left .Search .Search__input::-moz-placeholder {
          opacity: 1;
          font-size: 16px;
          color: #97A4B1; }
        .app .patient_panel__left .Search .Search__input:-moz-placeholder {
          opacity: 1;
          font-size: 16px;
          color: #97A4B1; }
        .app .patient_panel__left .Search .Search__input::placeholder {
          opacity: 1;
          font-size: 16px;
          color: #97A4B1; }
      .app .patient_panel__left .Search .Search__input__icon {
        right: 20px;
        left: auto; }
      .app .patient_panel__left .Search.search_opened .Search__input {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px; }
  .app .patient_panel__right {
    display: flex;
    justify-content: flex-end;
    flex: 0 0 50%;
    max-width: 50%; }
    .app .patient_panel__right .patient_panel__cta {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 49px;
      width: 200px;
      border-radius: 24px;
      background-color: #00A999;
      color: #fff;
      font-size: 16px;
      font-weight: 500; }
      .app .patient_panel__right .patient_panel__cta span {
        margin-left: 8px; }

.app .patient_form {
  display: block; }
  @media (min-width: 895px) {
    .app .patient_form {
      display: flex;
      flex-direction: row; } }
  .app .patient_form .patient_form__left {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0px;
    margin-bottom: 30px; }
    @media (min-width: 895px) {
      .app .patient_form .patient_form__left {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 22px;
        margin-bottom: 0px; } }
  .app .patient_form .patient_form__right {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0px; }
    @media (min-width: 895px) {
      .app .patient_form .patient_form__right {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 22px; } }
  .app .patient_form .form_element {
    width: 100%;
    margin-bottom: 10px; }
    .app .patient_form .form_element .Form__input {
      margin-bottom: 0;
      margin-top: 0px; }
      .app .patient_form .form_element .Form__input label {
        font-size: 11px;
        font-weight: 600;
        color: #2E2E2E;
        text-transform: uppercase;
        margin-bottom: 5px; }
      .app .patient_form .form_element .Form__input input {
        height: 51px;
        border: 1px solid #D9E3E7;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: none;
        opacity: 1;
        font-size: 14px;
        color: #97A4B1; }
        .app .patient_form .form_element .Form__input input::-webkit-input-placeholder {
          opacity: 1;
          font-size: 14px;
          color: #97A4B1; }
        .app .patient_form .form_element .Form__input input::-ms-input-placeholder {
          opacity: 1;
          font-size: 14px;
          color: #97A4B1; }
        .app .patient_form .form_element .Form__input input:-ms-input-placeholder {
          opacity: 1;
          font-size: 14px;
          color: #97A4B1; }
        .app .patient_form .form_element .Form__input input::-moz-placeholder {
          opacity: 1;
          font-size: 14px;
          color: #97A4B1; }
        .app .patient_form .form_element .Form__input input:-moz-placeholder {
          opacity: 1;
          font-size: 14px;
          color: #97A4B1; }
        .app .patient_form .form_element .Form__input input::placeholder {
          opacity: 1;
          font-size: 14px;
          color: #97A4B1; }
    .app .patient_form .form_element.form_element__textarea .form_element__textarea__wrap {
      position: relative; }
      .app .patient_form .form_element.form_element__textarea .form_element__textarea__wrap__controls {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 45px;
        border-top: solid 1px #D9E3E7;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: 0 13px; }
        .app .patient_form .form_element.form_element__textarea .form_element__textarea__wrap__controls__icons {
          display: flex; }
          .app .patient_form .form_element.form_element__textarea .form_element__textarea__wrap__controls__icons a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 31px;
            height: 31px;
            border: 1px solid #E8E8E8;
            border-color: transparent;
            border-radius: 2px;
            background-color: transparent;
            margin-right: 10px; }
            .app .patient_form .form_element.form_element__textarea .form_element__textarea__wrap__controls__icons a svg {
              color: #919191; }
            .app .patient_form .form_element.form_element__textarea .form_element__textarea__wrap__controls__icons a.active, .app .patient_form .form_element.form_element__textarea .form_element__textarea__wrap__controls__icons a:focus, .app .patient_form .form_element.form_element__textarea .form_element__textarea__wrap__controls__icons a:active {
              background-color: #F8FAFB;
              border-color: #E8E8E8; }
            .app .patient_form .form_element.form_element__textarea .form_element__textarea__wrap__controls__icons a:last-child {
              margin-right: 0; }
    .app .patient_form .form_element.form_element__textarea textarea {
      border: 1px solid #D9E3E7;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: none;
      resize: none;
      width: 100%;
      height: 500px;
      min-height: 0;
      opacity: 1;
      font-size: 14px;
      color: #97A4B1; }
    .app .patient_form .form_element.form_element__datepicker {
      margin-top: 31px; }
      .app .patient_form .form_element.form_element__datepicker > label {
        display: block;
        font-size: 11px;
        font-weight: 600;
        color: #2E2E2E;
        text-transform: uppercase;
        margin-bottom: 5px; }
      .app .patient_form .form_element.form_element__datepicker .form_element__datepicker__wrap {
        position: relative; }
        .app .patient_form .form_element.form_element__datepicker .form_element__datepicker__wrap__icon {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 56px;
          height: 51px;
          border-left: solid 1px #D9E3E7; }
          .app .patient_form .form_element.form_element__datepicker .form_element__datepicker__wrap__icon svg {
            width: 24px;
            height: 24px;
            color: #00A999; }
      .app .patient_form .form_element.form_element__datepicker .react-datepicker-wrapper {
        display: block; }
        .app .patient_form .form_element.form_element__datepicker .react-datepicker-wrapper .react-datepicker__input-container > input {
          height: 51px;
          width: 100%;
          border: 1px solid #D9E3E7;
          border-radius: 4px;
          background-color: #FFFFFF;
          box-shadow: none;
          padding: 16px 72px 16px 16px;
          outline: none !important; }
    .app .patient_form .form_element.form_element__radio-inline {
      display: flex;
      margin-bottom: 30px; }
      .app .patient_form .form_element.form_element__radio-inline > div {
        margin-right: 8px; }
        .app .patient_form .form_element.form_element__radio-inline > div input {
          display: none; }
        .app .patient_form .form_element.form_element__radio-inline > div label {
          position: relative;
          font-size: 14px;
          color: #2B2B2B;
          margin-bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 51px;
          border: 1px solid #D9E3E7;
          border-radius: 4px;
          background-color: #FFFFFF;
          padding-right: 21px;
          padding-left: 49px;
          cursor: pointer; }
          .app .patient_form .form_element.form_element__radio-inline > div label .check_round {
            left: 22px;
            width: 20px;
            height: 20px; }
            .app .patient_form .form_element.form_element__radio-inline > div label .check_round__inside {
              width: 10px;
              height: 10px;
              left: calc(50% - 5px);
              top: calc(50% - 5px); }
        .app .patient_form .form_element.form_element__radio-inline > div:last-child {
          margin-right: 0px; }

.app .patient_detail {
  height: 100%; }
  .app .patient_detail .patient_detail__data {
    padding: 27px 32px 0;
    height: calc(100vh - 315px);
    overflow-y: auto;
    overflow-x: hidden; }
    .app .patient_detail .patient_detail__data .data_protection .data_protection__cnt {
      margin-top: 30px; }
    .app .patient_detail .patient_detail__data .lab_results .lab_results__header {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .app .patient_detail .patient_detail__data .lab_results .lab_results__header .lab_results__header__title p {
        font-size: 18px;
        color: #2B2B2B;
        margin-bottom: 0px; }
      .app .patient_detail .patient_detail__data .lab_results .lab_results__header .lab_results__header__control a {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 24px;
        font-size: 14px;
        color: #00A999;
        border-radius: 24px;
        background-color: transparent;
        border: 2px solid #00A999; }
        .app .patient_detail .patient_detail__data .lab_results .lab_results__header .lab_results__header__control a svg {
          margin-right: 15px; }
    .app .patient_detail .patient_detail__data .lab_results .lab_results__dropzone {
      margin-top: 15px; }
    .app .patient_detail .patient_detail__data .lab_results .lab_results__body {
      margin-top: 25px; }
      .app .patient_detail .patient_detail__data .lab_results .lab_results__body .lab_results__body__tbl__controls {
        display: flex; }
        .app .patient_detail .patient_detail__data .lab_results .lab_results__body .lab_results__body__tbl__controls a {
          margin-right: 27px; }
          .app .patient_detail .patient_detail__data .lab_results .lab_results__body .lab_results__body__tbl__controls a:last-child {
            margin-right: 0; }
          .app .patient_detail .patient_detail__data .lab_results .lab_results__body .lab_results__body__tbl__controls a svg {
            display: block;
            width: 24px;
            height: 24px;
            color: #4B4B4B; }
    .app .patient_detail .patient_detail__data .details {
      display: flex;
      justify-content: space-between; }
      .app .patient_detail .patient_detail__data .details .details__left {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 15px;
        color: #2B2B2B; }
        .app .patient_detail .patient_detail__data .details .details__left__title {
          font-size: 15px;
          margin-bottom: 12px;
          font-weight: 600; }
        .app .patient_detail .patient_detail__data .details .details__left__text {
          font-size: 14px; }
      .app .patient_detail .patient_detail__data .details .details__right {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 15px; }
        .app .patient_detail .patient_detail__data .details .details__right .anamneses_card {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
          background-color: #fff;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
          margin-bottom: 10px; }
          .app .patient_detail .patient_detail__data .details .details__right .anamneses_card .anamneses_card__info {
            display: flex;
            align-items: center;
            flex: 1;
            padding: 15px 0 15px 15px;
            cursor: pointer; }
            .app .patient_detail .patient_detail__data .details .details__right .anamneses_card .anamneses_card__info .anamneses_card__info__icon {
              margin-right: 20px;
              cursor: pointer; }
            .app .patient_detail .patient_detail__data .details .details__right .anamneses_card .anamneses_card__info .anamneses_card__info__text p {
              color: #2B2B2B;
              margin-bottom: 0;
              font-size: 14px;
              font-weight: 600; }
              .app .patient_detail .patient_detail__data .details .details__right .anamneses_card .anamneses_card__info .anamneses_card__info__text p:nth-child(1) {
                font-size: 12px;
                font-weight: normal; }
          .app .patient_detail .patient_detail__data .details .details__right .anamneses_card .anamneses_card__cta {
            flex: 0 0 50px;
            width: 50px;
            max-width: 50px;
            padding: 15px 15px 15px 5px; }
            .app .patient_detail .patient_detail__data .details .details__right .anamneses_card .anamneses_card__cta a {
              color: #2B2B2B;
              font-size: 26px; }
          .app .patient_detail .patient_detail__data .details .details__right .anamneses_card:last-child {
            margin-bottom: 0px; }
  .app .patient_detail .patient_detail__tabs {
    margin-top: 18px; }

.app .anamnese_create .anamnese_create_header {
  padding: 0 0 20px;
  border-bottom: solid 1px #D9E3E7;
  margin-bottom: 30px; }

.app .anamnese_create .anamnese_create_data {
  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px; }

.app .anamnese_create .anamnese_create__info {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .app .anamnese_create .anamnese_create__info .anamnese_create__info__left {
    flex: 0 0 50%;
    max-width: 50%; }
    .app .anamnese_create .anamnese_create__info .anamnese_create__info__left .info_cell {
      display: flex;
      align-items: center; }
      .app .anamnese_create .anamnese_create__info .anamnese_create__info__left .info_cell__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
        margin-right: 12px;
        background-color: #fff; }
      .app .anamnese_create .anamnese_create__info .anamnese_create__info__left .info_cell__text p {
        font-size: 13px;
        color: #2B2B2B;
        margin-bottom: 0; }
        .app .anamnese_create .anamnese_create__info .anamnese_create__info__left .info_cell__text p:nth-child(1) {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 5px; }
  .app .anamnese_create .anamnese_create__info .anamnese_create__info__right {
    flex: 0 0 50%;
    max-width: 50%; }
    .app .anamnese_create .anamnese_create__info .anamnese_create__info__right .control_cell {
      display: flex;
      justify-content: flex-end; }
      .app .anamnese_create .anamnese_create__info .anamnese_create__info__right .control_cell__icon {
        margin-right: 12px; }
        .app .anamnese_create .anamnese_create__info .anamnese_create__info__right .control_cell__icon a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: solid 1px #2E2E2E; }
          .app .anamnese_create .anamnese_create__info .anamnese_create__info__right .control_cell__icon a svg {
            width: 24px;
            height: 24px;
            color: #2E2E2E; }
      .app .anamnese_create .anamnese_create__info .anamnese_create__info__right .control_cell__btn a {
        display: flex;
        align-items: center;
        height: 48px;
        padding: 0 24px;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        border-radius: 24px;
        background-color: #00A999; }
        .app .anamnese_create .anamnese_create__info .anamnese_create__info__right .control_cell__btn a img {
          margin-right: 15px; }

.app .anamnesis_details_header .theme_header {
  box-shadow: none;
  padding: 24px 0px 0; }

.app .anamnesis_detail__data {
  height: calc(100vh - 330px);
  overflow-y: auto;
  overflow-x: hidden; }
  .app .anamnesis_detail__data .anamnesis_detail__data__treatment .treatment_data_table {
    margin-top: 10px; }
  .app .anamnesis_detail__data .anamnesis_detail__data__treatment .treatment_header {
    display: flex;
    justify-content: space-between; }
    .app .anamnesis_detail__data .anamnesis_detail__data__treatment .treatment_header__title p {
      font-size: 18px;
      margin-bottom: 0px; }
  .app .anamnesis_detail__data .anamnesis_detail__data__anamnese {
    display: flex;
    justify-content: center; }
    .app .anamnesis_detail__data .anamnesis_detail__data__anamnese .theme_questions {
      width: 800px;
      margin-top: 40px; }
  .app .anamnesis_detail__data .anamnesis_detail__data__overview {
    display: flex;
    justify-content: space-between; }
    .app .anamnesis_detail__data .anamnesis_detail__data__overview .overview_left {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 15px; }
      .app .anamnesis_detail__data .anamnesis_detail__data__overview .overview_left__relevance {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: solid 1px #DEE0E1; }
        .app .anamnesis_detail__data .anamnesis_detail__data__overview .overview_left__relevance:last-child {
          margin-bottom: 0px; }
        .app .anamnesis_detail__data .anamnesis_detail__data__overview .overview_left__relevance .left {
          flex: 1;
          padding-right: 25px; }
        .app .anamnesis_detail__data .anamnesis_detail__data__overview .overview_left__relevance .right {
          width: 120px; }
    .app .anamnesis_detail__data .anamnesis_detail__data__overview .overview_right {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 15px; }

.app .treatment_details .treatment_details_header .theme_header {
  box-shadow: none;
  padding: 24px 0; }

.app .treatment_details .treatment_details__success {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  box-shadow: inset 0 1px 0 0 #E8E8E8, inset 0 -1px 0 0 #DEE0E1; }
  .app .treatment_details .treatment_details__success__left p {
    margin-bottom: 0px; }

.app .treatment_details .treatment_details__form {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  padding: 0 35px;
  height: calc(100vh - 310px);
  overflow-y: auto;
  overflow-x: hidden; }
  .app .treatment_details .treatment_details__form .homework_area {
    position: relative; }
  .app .treatment_details .treatment_details__form .treatment_details__form__control {
    position: absolute;
    top: 50%;
    color: #00A999;
    font-size: 24px; }
    .app .treatment_details .treatment_details__form .treatment_details__form__control.left {
      left: 0; }
    .app .treatment_details .treatment_details__form .treatment_details__form__control.right {
      right: 0; }
  .app .treatment_details .treatment_details__form .form_textarea {
    position: relative; }
    .app .treatment_details .treatment_details__form .form_textarea .Form__input {
      margin-bottom: 0px; }
      .app .treatment_details .treatment_details__form .form_textarea .Form__input label {
        color: #757575;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px; }
      .app .treatment_details .treatment_details__form .form_textarea .Form__input textarea,
      .app .treatment_details .treatment_details__form .form_textarea .Form__input .read_only_textarea {
        border: 1px solid #D9E3E7;
        border-bottom-color: transparent;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: none;
        resize: none;
        width: 100%;
        height: auto;
        min-height: 454px;
        opacity: 1;
        font-size: 14px;
        color: #4B4B4B; }
        .app .treatment_details .treatment_details__form .form_textarea .Form__input textarea .homelessons_list.hidden,
        .app .treatment_details .treatment_details__form .form_textarea .Form__input .read_only_textarea .homelessons_list.hidden {
          display: none; }
    .app .treatment_details .treatment_details__form .form_textarea .controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 45px;
      background-color: #fff;
      border: solid 1px #D9E3E7;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 0 13px; }
      .app .treatment_details .treatment_details__form .form_textarea .controls.single {
        justify-content: flex-end; }
      .app .treatment_details .treatment_details__form .form_textarea .controls__add {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        color: #00A999; }
        .app .treatment_details .treatment_details__form .form_textarea .controls__add svg {
          margin-right: 10px; }
      .app .treatment_details .treatment_details__form .form_textarea .controls__icons {
        display: flex; }
        .app .treatment_details .treatment_details__form .form_textarea .controls__icons a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 31px;
          height: 31px;
          border: 1px solid #E8E8E8;
          border-color: transparent;
          border-radius: 2px;
          background-color: transparent;
          margin-right: 10px; }
          .app .treatment_details .treatment_details__form .form_textarea .controls__icons a svg {
            color: #919191; }
          .app .treatment_details .treatment_details__form .form_textarea .controls__icons a.active, .app .treatment_details .treatment_details__form .form_textarea .controls__icons a:focus, .app .treatment_details .treatment_details__form .form_textarea .controls__icons a:active {
            background-color: #F8FAFB;
            border-color: #E8E8E8; }
          .app .treatment_details .treatment_details__form .form_textarea .controls__icons a:last-child {
            margin-right: 0; }
  .app .treatment_details .treatment_details__form__left {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 15px; }
  .app .treatment_details .treatment_details__form__right {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 15px; }

.app .theme_questions .question {
  padding-bottom: 14px;
  margin-bottom: 14px;
  border-bottom: solid 1px #E6EAEC; }
  .app .theme_questions .question:last-child {
    border-bottom: none;
    margin-bottom: 0px; }
  .app .theme_questions .question .question__title {
    position: relative;
    margin-bottom: 12px; }
    .app .theme_questions .question .question__title p {
      font-size: 14px;
      font-weight: 600;
      color: #2B2B2B;
      margin-bottom: 0;
      padding-right: 150px; }
    .app .theme_questions .question .question__title.question__title--inlarge {
      margin-bottom: 0px; }
      .app .theme_questions .question .question__title.question__title--inlarge p {
        font-size: 16px; }
    .app .theme_questions .question .question__title .relevance {
      position: absolute;
      top: 0;
      right: 0; }
      .app .theme_questions .question .question__title .relevance .Relevance__check {
        position: relative;
        width: 18px;
        height: 18px;
        border: solid 1px #00A999;
        margin-right: 6px; }
        .app .theme_questions .question .question__title .relevance .Relevance__check:last-child {
          margin-right: 0px; }
        .app .theme_questions .question .question__title .relevance .Relevance__check:before {
          content: '';
          display: none;
          position: absolute;
          left: 2px;
          top: 2px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #00A999; }
        .app .theme_questions .question .question__title .relevance .Relevance__check--checked {
          background-color: transparent;
          border-color: #00A999; }
          .app .theme_questions .question .question__title .relevance .Relevance__check--checked:before {
            display: block; }
  .app .theme_questions .question .question__body {
    position: relative; }
    .app .theme_questions .question .question__body .question__body__wrap {
      position: relative;
      user-select: none; }
    .app .theme_questions .question .question__body .speech_recognition_control {
      position: absolute;
      width: 40px;
      height: 100%;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(230, 234, 236, 0.4);
      cursor: pointer;
      user-select: none; }
      .app .theme_questions .question .question__body .speech_recognition_control.speech_recognition_control--record {
        opacity: 1;
        left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-right: solid 1px #E6EAEC;
        background-image: url("../assets/img/recording_microphone.png");
        background-position: center center;
        background-repeat: no-repeat; }
        .app .theme_questions .question .question__body .speech_recognition_control.speech_recognition_control--record.listening {
          opacity: .4; }
        .app .theme_questions .question .question__body .speech_recognition_control.speech_recognition_control--record.not_supported {
          font-size: 8px;
          color: red; }
      .app .theme_questions .question .question__body .speech_recognition_control.speech_recognition_control--delete {
        right: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: solid 1px #E6EAEC; }
    .app .theme_questions .question .question__body .Answer__optional__form {
      position: relative; }
    .app .theme_questions .question .question__body .Form__input {
      position: relative; }
      .app .theme_questions .question .question__body .Form__input .Form__textarea__disabled,
      .app .theme_questions .question .question__body .Form__input .Form__input__disabled {
        box-shadow: none;
        background-color: #fff;
        border: 1px solid #E6EAEC; }
      .app .theme_questions .question .question__body .Form__input input[type='text'] {
        padding: 0 50px;
        font-size: 14px;
        height: 51px;
        border: 1px solid #E6EAEC;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: none; }
      .app .theme_questions .question .question__body .Form__input textarea {
        padding: 16px 50px;
        font-size: 14px;
        border: 1px solid #E6EAEC;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: none;
        resize: none; }
    .app .theme_questions .question .question__body .space_holder {
      width: 100%;
      height: 18px;
      margin-bottom: 12px; }

.app .anamnese_form .Answer__files {
  padding: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 8px; }
  .app .anamnese_form .Answer__files span {
    display: inline-flex;
    align-items: center;
    margin-right: 12px; }
  .app .anamnese_form .Answer__files svg {
    margin-right: 6px; }
  .app .anamnese_form .Answer__files button {
    display: inline-flex;
    justify-content: center; }

.app .anamnese_form .anamnese_form__giagnosis .anamnese_form__giagnosis__select {
  margin-bottom: 20px; }
  .app .anamnese_form .anamnese_form__giagnosis .anamnese_form__giagnosis__select .Form__input {
    margin: 0; }
  .app .anamnese_form .anamnese_form__giagnosis .anamnese_form__giagnosis__select .Form__input__label {
    color: #2E2E2E;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 5px; }
  .app .anamnese_form .anamnese_form__giagnosis .anamnese_form__giagnosis__select .react-autosuggest__suggestions-container--open {
    top: -2px;
    border: 1px solid #D9E3E7;
    border-top: 1px solid transparent; }
  .app .anamnese_form .anamnese_form__giagnosis .anamnese_form__giagnosis__select .react-autosuggest__input {
    height: 51px;
    border: 1px solid #D9E3E7;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: none;
    margin-top: 0px;
    margin-bottom: 0px;
    opacity: 1;
    font-size: 14px;
    color: #2E2E2E; }
    .app .anamnese_form .anamnese_form__giagnosis .anamnese_form__giagnosis__select .react-autosuggest__input::-webkit-input-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #2E2E2E; }
    .app .anamnese_form .anamnese_form__giagnosis .anamnese_form__giagnosis__select .react-autosuggest__input::-ms-input-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #2E2E2E; }
    .app .anamnese_form .anamnese_form__giagnosis .anamnese_form__giagnosis__select .react-autosuggest__input:-ms-input-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #2E2E2E; }
    .app .anamnese_form .anamnese_form__giagnosis .anamnese_form__giagnosis__select .react-autosuggest__input::-moz-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #2E2E2E; }
    .app .anamnese_form .anamnese_form__giagnosis .anamnese_form__giagnosis__select .react-autosuggest__input:-moz-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #2E2E2E; }
    .app .anamnese_form .anamnese_form__giagnosis .anamnese_form__giagnosis__select .react-autosuggest__input::placeholder {
      opacity: 1;
      font-size: 14px;
      color: #2E2E2E; }

.app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea {
  position: relative; }
  .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .Form__input label {
    color: #2E2E2E;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 5px; }
  .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .Form__input textarea,
  .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .Form__input .read_only_textarea {
    border: 1px solid #D9E3E7;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: none;
    resize: none;
    width: 100%;
    height: auto;
    min-height: 400px;
    padding: 15px;
    opacity: 1;
    font-size: 14px;
    color: #4B4B4B; }
    .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .Form__input textarea ul,
    .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .Form__input .read_only_textarea ul {
      padding: 0;
      margin: 0;
      list-style-position: inside; }
      .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .Form__input textarea ul li,
      .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .Form__input .read_only_textarea ul li {
        color: #4B4B4B;
        font-size: 14px; }
  .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .controls {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 45px;
    border-top: solid 1px #D9E3E7;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 0 13px; }
    .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .controls__icons {
      display: flex; }
      .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .controls__icons a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 31px;
        height: 31px;
        border: 1px solid #E8E8E8;
        border-color: transparent;
        border-radius: 2px;
        background-color: transparent;
        margin-right: 10px; }
        .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .controls__icons a svg {
          color: #919191; }
        .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .controls__icons a.active, .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .controls__icons a:focus, .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .controls__icons a:active {
          background-color: #F8FAFB;
          border-color: #E8E8E8; }
        .app .anamnese_form .anamnese_form__additional-info .anamnese_form__additional-info__textarea .controls__icons a:last-child {
          margin-right: 0; }

.app .graph_editor {
  position: relative;
  height: 100%; }
  .app .graph_editor .graph_panel {
    height: 100%;
    width: 100%;
    padding: 16px; }
    .app .graph_editor .graph_panel .graph_panel__inner {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
  .app .graph_editor .graph_panel_dates_switcher {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2; }
    .app .graph_editor .graph_panel_dates_switcher.control_panel_activated {
      top: 130px; }
    .app .graph_editor .graph_panel_dates_switcher .graph_panel_dates_switcher__title {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #757575;
      margin-bottom: 5px; }
      .app .graph_editor .graph_panel_dates_switcher .graph_panel_dates_switcher__title span {
        margin-left: 7px; }
    .app .graph_editor .graph_panel_dates_switcher .graph_panel_dates_switcher__opts .theme_radio {
      margin-bottom: 5px; }
      .app .graph_editor .graph_panel_dates_switcher .graph_panel_dates_switcher__opts .theme_radio label {
        color: #757575; }
      .app .graph_editor .graph_panel_dates_switcher .graph_panel_dates_switcher__opts .theme_radio:last-child {
        margin-bottom: 0px; }
  .app .graph_editor .graph_panel_svg {
    position: relative;
    display: block;
    width: 100%;
    height: 100%; }
    .app .graph_editor .graph_panel_svg .graph_panel_svg__img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; }
    .app .graph_editor .graph_panel_svg.fixed_size {
      width: 960px;
      height: 775px; }
      @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
        .app .graph_editor .graph_panel_svg.fixed_size {
          height: 620px; } }
    .app .graph_editor .graph_panel_svg__svg {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 1; }
      .app .graph_editor .graph_panel_svg__svg svg {
        position: absolute;
        cursor: pointer; }
  .app .graph_editor .graph_panel_form_tutorial {
    position: absolute;
    top: calc(50% - 154.5px);
    left: calc(50% - 150.5px);
    z-index: 3;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 309px;
    width: 301px;
    border: 1px solid #E6EAEC;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.1); }
    .app .graph_editor .graph_panel_form_tutorial__title {
      position: relative;
      margin-top: 20px;
      padding: 0 42px;
      text-align: center;
      color: #2B2B2B;
      font-size: 14px;
      font-weight: 600; }
      .app .graph_editor .graph_panel_form_tutorial__title:before {
        content: "";
        position: absolute;
        left: calc(50% - 17px);
        top: calc(100% + 12px);
        width: 34px;
        height: 2px;
        border-radius: 2px;
        background-color: #00A999; }
    .app .graph_editor .graph_panel_form_tutorial__desc {
      padding: 0 42px;
      text-align: center;
      color: #2B2B2B;
      font-size: 14px;
      font-weight: 300;
      margin-top: 28px; }
    .app .graph_editor .graph_panel_form_tutorial__cnt {
      margin-top: 20px;
      padding: 0 20px; }
  .app .graph_editor .graph_panel_form {
    position: absolute;
    z-index: 2;
    width: 292px;
    height: 280px;
    border: 1px solid #E6EAEC;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 20px; }
    .app .graph_editor .graph_panel_form.read-only {
      height: 180px; }
    .app .graph_editor .graph_panel_form.hidden {
      display: none; }
    .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__options {
      margin-top: 20px; }
      .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__options .opts {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 17px; }
        .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__options .opts:last-child {
          margin-bottom: 0; }
    .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__slider {
      margin-top: 15px; }
      .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__slider .title {
        color: #757575;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 11px; }
      .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__slider .slider .noUi-target {
        height: 11px;
        border: 1px solid #D9E3E7;
        border-radius: 5.5px;
        background-color: #fff; }
      .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__slider .slider .noUi-pips {
        height: auto;
        padding: 0;
        top: calc(100% + 10px); }
        .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__slider .slider .noUi-pips .noUi-marker {
          display: none; }
        .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__slider .slider .noUi-pips .noUi-value {
          color: #2B2B2B;
          font-size: 12px;
          font-weight: 500; }
      .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__slider .slider .noUi-base .noUi-connect {
        border-radius: 2.5px;
        height: 5px;
        background: linear-gradient(270deg, #ED3D3D 0%, #FFC400 100%);
        box-shadow: none;
        top: 2px !important;
        left: 2px !important; }
      .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__slider .slider .noUi-base .noUi-origin .noUi-handle {
        height: 24px;
        width: 24px;
        background-color: #fff;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.25);
        border: none;
        border-radius: 50%; }
        .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__slider .slider .noUi-base .noUi-origin .noUi-handle:before, .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__slider .slider .noUi-base .noUi-origin .noUi-handle:after {
          display: none; }
    .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__controls {
      margin-top: 40px; }
      .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__controls > * {
        margin-bottom: 10px; }
        .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__controls > *:last-child {
          margin-bottom: 0; }
    .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__desc {
      height: 51px;
      width: 100%;
      border: 1px solid #E6EAEC;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: none !important;
      outline: none !important;
      padding: 0 18px;
      opacity: 1;
      font-size: 14px;
      color: #2E2E2E; }
      .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__desc::-webkit-input-placeholder {
        opacity: 1;
        font-size: 14px;
        color: #2E2E2E; }
      .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__desc::-ms-input-placeholder {
        opacity: 1;
        font-size: 14px;
        color: #2E2E2E; }
      .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__desc:-ms-input-placeholder {
        opacity: 1;
        font-size: 14px;
        color: #2E2E2E; }
      .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__desc::-moz-placeholder {
        opacity: 1;
        font-size: 14px;
        color: #2E2E2E; }
      .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__desc:-moz-placeholder {
        opacity: 1;
        font-size: 14px;
        color: #2E2E2E; }
      .app .graph_editor .graph_panel_form .graph_panel_form__input .graph_panel_form__input__desc::placeholder {
        opacity: 1;
        font-size: 14px;
        color: #2E2E2E; }
  .app .graph_editor .graph_editor__control {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2; }
    .app .graph_editor .graph_editor__control .graph_editor__control__button-wrapper {
      position: relative;
      display: flex;
      align-items: start; }
      .app .graph_editor .graph_editor__control .graph_editor__control__button-wrapper.active {
        color: #00A999; }
        .app .graph_editor .graph_editor__control .graph_editor__control__button-wrapper.active .graph_editor__control__button-wrapper__button span {
          color: #00A999; }
      .app .graph_editor .graph_editor__control .graph_editor__control__button-wrapper:last-child {
        margin-bottom: 0px; }
      .app .graph_editor .graph_editor__control .graph_editor__control__button-wrapper .graph_editor__control__button-wrapper__button {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 5px; }
        .app .graph_editor .graph_editor__control .graph_editor__control__button-wrapper .graph_editor__control__button-wrapper__button span {
          font-size: 14px;
          color: #757575;
          margin-left: 7px; }
      .app .graph_editor .graph_editor__control .graph_editor__control__button-wrapper .graph_editor__control__button-wrapper__colors {
        position: absolute;
        display: flex;
        left: 0;
        top: 30px; }

.app .file_dropzone_modal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8; }
  .app .file_dropzone_modal .file_dropzone_modal__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9; }
  .app .file_dropzone_modal .file_dropzone_modal__inner {
    position: relative;
    z-index: 10;
    width: 326px;
    border: 1px solid #E6EAEC;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 15px; }
  .app .file_dropzone_modal .file_dropzone_modal__name .Form__input .Form__input__disabled {
    min-height: 0;
    height: 51px;
    border: 1px solid #D9E3E7;
    border-radius: 4px;
    background-color: #fff;
    opacity: 1;
    font-size: 14px;
    color: #97A4B1; }
    .app .file_dropzone_modal .file_dropzone_modal__name .Form__input .Form__input__disabled::-webkit-input-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #97A4B1; }
    .app .file_dropzone_modal .file_dropzone_modal__name .Form__input .Form__input__disabled::-ms-input-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #97A4B1; }
    .app .file_dropzone_modal .file_dropzone_modal__name .Form__input .Form__input__disabled:-ms-input-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #97A4B1; }
    .app .file_dropzone_modal .file_dropzone_modal__name .Form__input .Form__input__disabled::-moz-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #97A4B1; }
    .app .file_dropzone_modal .file_dropzone_modal__name .Form__input .Form__input__disabled:-moz-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #97A4B1; }
    .app .file_dropzone_modal .file_dropzone_modal__name .Form__input .Form__input__disabled::placeholder {
      opacity: 1;
      font-size: 14px;
      color: #97A4B1; }
  .app .file_dropzone_modal .file_dropzone_modal__name .Form__input input[type='text'] {
    height: 51px;
    width: 100%;
    border: 1px solid #D9E3E7;
    border-radius: 4px;
    background-color: #fff;
    opacity: 1;
    font-size: 14px;
    color: #97A4B1; }
    .app .file_dropzone_modal .file_dropzone_modal__name .Form__input input[type='text']::-webkit-input-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #97A4B1; }
    .app .file_dropzone_modal .file_dropzone_modal__name .Form__input input[type='text']::-ms-input-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #97A4B1; }
    .app .file_dropzone_modal .file_dropzone_modal__name .Form__input input[type='text']:-ms-input-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #97A4B1; }
    .app .file_dropzone_modal .file_dropzone_modal__name .Form__input input[type='text']::-moz-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #97A4B1; }
    .app .file_dropzone_modal .file_dropzone_modal__name .Form__input input[type='text']:-moz-placeholder {
      opacity: 1;
      font-size: 14px;
      color: #97A4B1; }
    .app .file_dropzone_modal .file_dropzone_modal__name .Form__input input[type='text']::placeholder {
      opacity: 1;
      font-size: 14px;
      color: #97A4B1; }
  .app .file_dropzone_modal .file_dropzone_modal__zone .FileDropzone {
    padding: 0;
    border-radius: 0;
    border: 0; }
  .app .file_dropzone_modal .file_dropzone_modal__zone .existed_segment {
    padding: 20px 0; }
    .app .file_dropzone_modal .file_dropzone_modal__zone .existed_segment .existed_segment__inner {
      display: flex;
      align-items: center;
      flex-direction: column; }
    .app .file_dropzone_modal .file_dropzone_modal__zone .existed_segment .existed_segment__circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: solid 1px #00A999;
      background-color: #00A999;
      color: #fff;
      margin-bottom: 10px; }
    .app .file_dropzone_modal .file_dropzone_modal__zone .existed_segment .adding_segment__file-info.edit {
      margin-bottom: 10px; }
    .app .file_dropzone_modal .file_dropzone_modal__zone .existed_segment .adding_segment__file-info p {
      color: #2E2E2E;
      margin-bottom: 0px;
      text-align: center; }
  .app .file_dropzone_modal .file_dropzone_modal__zone .adding_segment {
    padding: 20px 0; }
    .app .file_dropzone_modal .file_dropzone_modal__zone .adding_segment .adding_segment__inner {
      display: flex;
      align-items: center;
      flex-direction: column; }
    .app .file_dropzone_modal .file_dropzone_modal__zone .adding_segment .adding_segment__circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: solid 1px #00A999;
      color: #00A999; }
    .app .file_dropzone_modal .file_dropzone_modal__zone .adding_segment .adding_segment__text {
      font-size: 14px;
      font-weight: 500;
      color: #00A999;
      margin-bottom: 0px;
      text-align: center; }
  .app .file_dropzone_modal .file_dropzone_modal__cnt .theme_btn:nth-child(1) {
    margin-bottom: 10px; }

.app .home_lessons_editor {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px; }
  .app .home_lessons_editor.active {
    display: block; }
  .app .home_lessons_editor .home_lessons_edItor__searchbar {
    display: flex;
    margin-bottom: 15px; }
    .app .home_lessons_editor .home_lessons_edItor__searchbar .home_lessons_editor__searchbar__search .theme_text_input {
      padding: 16px 48px 16px 48px; }
    .app .home_lessons_editor .home_lessons_edItor__searchbar .home_lessons_edItor__searchbar__cnt {
      height: 51px;
      border-radius: 4px;
      margin-left: 15px; }
    .app .home_lessons_editor .home_lessons_edItor__searchbar .Search__input__close {
      right: 50px; }
  .app .home_lessons_editor .home_lessons_edItor__groups {
    max-height: 300px;
    overflow-y: auto; }
    .app .home_lessons_editor .home_lessons_edItor__groups .groups_list {
      padding: 0;
      margin: 0;
      list-style: none; }
      .app .home_lessons_editor .home_lessons_edItor__groups .groups_list .groups_list__category {
        margin-bottom: 25px; }
        .app .home_lessons_editor .home_lessons_edItor__groups .groups_list .groups_list__category .groups_list__category__title {
          display: block;
          margin-bottom: 10px; }
        .app .home_lessons_editor .home_lessons_edItor__groups .groups_list .groups_list__category .groups_list__category__lesson .theme_checkbox label {
          font-size: 14px;
          font-weight: 400;
          user-select: none; }
        .app .home_lessons_editor .home_lessons_edItor__groups .groups_list .groups_list__category .groups_list__category__title__add-lesson .add_lesson_cta {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #757575;
          margin-top: 10px; }
          .app .home_lessons_editor .home_lessons_edItor__groups .groups_list .groups_list__category .groups_list__category__title__add-lesson .add_lesson_cta__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            border: solid 1px #00A999;
            padding: 5px;
            color: #00A999;
            margin-right: 10px; }
        .app .home_lessons_editor .home_lessons_edItor__groups .groups_list .groups_list__category .groups_list__category__title__add-lesson .AddLesson__form .AddLesson__form__input .theme_text_input {
          height: 40px !important;
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important; }
        .app .home_lessons_editor .home_lessons_edItor__groups .groups_list .groups_list__category .groups_list__category__title__add-lesson .AddLesson__form .AddLesson__submit {
          border-color: #00A999;
          background-color: #00A999;
          opacity: 1; }
          .app .home_lessons_editor .home_lessons_edItor__groups .groups_list .groups_list__category .groups_list__category__title__add-lesson .AddLesson__form .AddLesson__submit:disabled {
            opacity: .7; }
        .app .home_lessons_editor .home_lessons_edItor__groups .groups_list .groups_list__category:last-child {
          margin-bottom: 0; }

.app .basic_upload_button .basic_upload_button__files__file a {
  display: inline-flex;
  color: #00A999;
  padding: 5px;
  font-size: 13px;
  align-items: center;
  padding: 5px; }

.app .speech_recognition_control {
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(230, 234, 236, 0.4);
  cursor: pointer;
  user-select: none; }
  .app .speech_recognition_control.speech_recognition_control--record {
    opacity: 1;
    left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-image: url("../assets/img/recording_microphone.png");
    background-position: center center;
    background-repeat: no-repeat; }
    .app .speech_recognition_control.speech_recognition_control--record.listening {
      opacity: .4; }
    .app .speech_recognition_control.speech_recognition_control--record.not_supported {
      font-size: 8px;
      color: red; }
  .app .speech_recognition_control.speech_recognition_control--absolute {
    position: absolute;
    top: 10px;
    right: 7px;
    left: auto;
    border: 4px; }

.app .app_left_paddings {
  padding: 0 32px; }

.app .app_top_nav_bar {
  width: 100%;
  height: 64px;
  background-color: #FFFFFF;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 8;
  width: 100%;
  top: 0; }
  .app .app_top_nav_bar.override {
    z-index: 9; }
  .app .app_top_nav_bar .app_top_nav_bar__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 32px; }
    .app .app_top_nav_bar .app_top_nav_bar__inner.app_top_nav_bar__inner--flex-layout .app_top_nav_bar__left-link {
      flex: 0 0 33.3333333%;
      max-width: 33.3333333%; }
    .app .app_top_nav_bar .app_top_nav_bar__inner.app_top_nav_bar__inner--flex-layout .app_top_nav_bar__page-title {
      flex: 0 0 33.3333333%;
      max-width: 33.3333333%; }
    .app .app_top_nav_bar .app_top_nav_bar__inner.app_top_nav_bar__inner--flex-layout .app_top_nav_bar__right-link {
      flex: 0 0 33.3333333%;
      max-width: 33.3333333%;
      display: flex;
      justify-content: flex-end; }
    .app .app_top_nav_bar .app_top_nav_bar__inner.app_top_nav_bar__inner--flex-layout .app_top_nav_bar__logo {
      flex: 0 0 33.3333333%;
      max-width: 33.3333333%;
      display: flex;
      justify-content: flex-start; }
    .app .app_top_nav_bar .app_top_nav_bar__inner.app_top_nav_bar__inner--flex-layout .app_top_nav_bar__auth-info {
      flex: 0 0 33.3333333%;
      max-width: 33.3333333%;
      display: flex;
      justify-content: flex-end; }
    .app .app_top_nav_bar .app_top_nav_bar__inner .app_top_nav_bar__left-link a {
      color: #00A999;
      font-size: 15px; }
    .app .app_top_nav_bar .app_top_nav_bar__inner .app_top_nav_bar__right-link a {
      color: #00A999;
      font-size: 15px; }
    .app .app_top_nav_bar .app_top_nav_bar__inner .app_top_nav_bar__logo .logo {
      display: flex;
      justify-content: center;
      align-items: center; }
      .app .app_top_nav_bar .app_top_nav_bar__inner .app_top_nav_bar__logo .logo .logo__cta {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #00A999;
        margin-right: 12px;
        border: solid 1px #00A999; }
        .app .app_top_nav_bar .app_top_nav_bar__inner .app_top_nav_bar__logo .logo .logo__cta img {
          width: 100%;
          border-radius: 50%; }
      .app .app_top_nav_bar .app_top_nav_bar__inner .app_top_nav_bar__logo .logo .logo__text {
        font-size: 14px;
        font-weight: 600;
        color: #2E2E2E;
        text-transform: uppercase; }
    .app .app_top_nav_bar .app_top_nav_bar__inner .app_top_nav_bar__page-title {
      color: #2E2E2E;
      font-size: 17px;
      font-weight: 600;
      text-align: center; }
      .app .app_top_nav_bar .app_top_nav_bar__inner .app_top_nav_bar__page-title p {
        color: #2E2E2E;
        font-size: 17px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 0; }
        .app .app_top_nav_bar .app_top_nav_bar__inner .app_top_nav_bar__page-title p:last-child {
          font-weight: 400;
          font-size: 14px;
          margin-top: 5px; }
    .app .app_top_nav_bar .app_top_nav_bar__inner .app_top_nav_bar__auth-info .avatar {
      display: flex;
      justify-content: center;
      align-items: center; }
      .app .app_top_nav_bar .app_top_nav_bar__inner .app_top_nav_bar__auth-info .avatar__image {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #00A999;
        margin-left: 12px; }
      .app .app_top_nav_bar .app_top_nav_bar__inner .app_top_nav_bar__auth-info .avatar__text {
        color: #2E2E2E;
        font-size: 14px; }

.app .app_container {
  position: relative;
  background-color: #f9fafb;
  overflow: hidden;
  padding-top: 64px; }
  .app .app_container__inner {
    position: relative;
    padding: 15px 32px 0;
    height: calc(100vh - 114px); }
    .app .app_container__inner.fullWidth {
      padding: 0; }
    .app .app_container__inner.customHead {
      padding: 0; }
    .app .app_container__inner.paddingLess {
      padding: 0; }
    .app .app_container__inner.whiteBackground {
      background-color: #fff; }

.app .app_bottom_nav_bar {
  width: 100%;
  height: 50px;
  background-color: #FFFFFF;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 10;
  bottom: 0; }
  .app .app_bottom_nav_bar .app_bottom_nav_bar__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 12px; }
    .app .app_bottom_nav_bar .app_bottom_nav_bar__inner .app_bottom_nav_bar__link {
      color: #757575;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .app .app_bottom_nav_bar .app_bottom_nav_bar__inner .app_bottom_nav_bar__link span {
        font-size: 10px; }
      .app .app_bottom_nav_bar .app_bottom_nav_bar__inner .app_bottom_nav_bar__link.green_variant {
        color: #00A999; }
      .app .app_bottom_nav_bar .app_bottom_nav_bar__inner .app_bottom_nav_bar__link.hidden {
        visibility: hidden; }
      .app .app_bottom_nav_bar .app_bottom_nav_bar__inner .app_bottom_nav_bar__link:hover, .app .app_bottom_nav_bar .app_bottom_nav_bar__inner .app_bottom_nav_bar__link:active, .app .app_bottom_nav_bar .app_bottom_nav_bar__inner .app_bottom_nav_bar__link:focus {
        color: #00A999; }
