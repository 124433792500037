.icon-enabled-link {
    display: flex;
    align-items: center;
    .icon {
        margin-right: 5px;
    }
}

.icon {
    display: block;
    position: relative;
    &.icon-navi-left {
        width: 7px;
        height: 7px;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            transform: rotate(135deg);
            width: 7px;
            height: 7px;
            border-color: #00A999;
            border-style: solid;
            border-width: 0 1px 1px 0;
        }
    }
}