:root {
  --white: hsl(0, 0%, 100%);
  --white-hover: hsl(0, 0%, 80%);

  --text-gray-1: hsl(0, 0%, 97%);
  --text-gray-2: hsl(0, 0%, 85%);
  --text-gray-3: hsl(0, 0%, 65%);
  --text-gray-4: hsl(0, 0%, 50%);
  --text-gray-5: hsl(0, 0%, 40%);
  --text-gray-6: hsl(0, 0%, 20%);

  --bg-gray-1: hsl(210, 14%, 97.7%);
  --bg-gray-2: hsl(210, 10.1%, 94.7%); 
  --bg-gray-3: hsl(210, 10%, 92.3%);
  --bg-gray-4: hsl(210, 10%, 85.6%);
  --bg-gray-5: hsl(210, 9.8%, 80.1%);
  --bg-gray-6: hsl(210, 10.9%, 70.9%);
  --bg-gray-7: hsl(210, 10.9%, 60.9%);
  --bg-gray-8: hsl(210, 10.9%, 50.9%);
  --bg-gray-9: hsl(210, 10.9%, 40.9%);

  --gray: #bbb;
  --gray-darker: #aaa;

  --primary-color: hsl(200.9, 100%, 50.1%);
  --primary-color-hover: hsl(200, 100%, 40%);

  --button-color: hsl(200, 100%, 30%);
  --button-color-hover: hsl(200, 100%, 15%);
  --button-color-disabled: hsl(200, 40%, 60%);

  --bg-hover: var(--bg-gray-2);

  --link-color: lightslategray;
  --link-color-hover: #9ca8b4;

  --green: rgb(45, 194, 26);
  --green-darker: rgb(42, 153, 28);
  --red: rgb(221, 53, 53);
  --red-darker: #861212;

  --navbar-bg-color: var(--bg-gray-2);
  --main-bg-color: hsl(200, 50%, 85%);
  --main-text-color: var(--text-gray-5);

  --success-color: var(--green);
  --error-color: var(--red);
}
  