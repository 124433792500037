.anamnesis_details_header {
    .theme_header {
        box-shadow: none;
        padding: 24px 0px 0;
    }
}

.anamnesis_detail__data {
    height: calc(100vh - 330px);
    overflow-y: auto;
    overflow-x: hidden;
    .anamnesis_detail__data__treatment {
        .treatment_data_table {
            margin-top: 10px;
        }
        .treatment_header {
            display: flex;
            justify-content: space-between;
            &__title {
                p {
                    font-size: 18px;
                    margin-bottom: 0px;
                }
            }
        }
    }
    .anamnesis_detail__data__anamnese {
        display: flex;
        justify-content: center;
        .theme_questions {
            width: 800px;
            margin-top: 40px;
        }
    }
    .anamnesis_detail__data__overview {
        display: flex;
        justify-content: space-between;
        .overview_left {
            flex: 0 0 50%;
            max-width: 50%;
            padding-right: 15px;
            &__relevance {
                display: flex;
                justify-content: space-between;
                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: solid 1px #DEE0E1;
                &:last-child {
                    margin-bottom: 0px;
                }
                .left {
                    flex: 1;
                    padding-right: 25px;
                }
                .right {
                    width: 120px;
                }
            }
        }
        .overview_right {
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: 15px;
        }
    }
}