@import '../../style/breakpoints';

.Search {
  --searchbar-height: 48px;
  position: relative;
  font-size: var(--font-size-5);
  width: 100%;

  &__form {
    width: 100%;
  }

  &__input {
    background-color: var(--bg-gray-1);
    border: 2px solid var(--bg-gray-5);
    border-radius: 8px;
    box-shadow: var(--elevation-1);
    width: 100%;
    height: var(--searchbar-height);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 44px;
    outline: 0;
    transition: box-shadow .2s;

    @media (min-width: $sm-to-md) {
      padding: 0 60px;
    }

    @media (max-width: $xs-to-sm) {
      &::placeholder {
        color: transparent;
      }
    }

    &:focus, &:active {
      border-color: var(--bg-gray-8);
    }

    &__icon, &__close {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: var(--search-icon-index);
    }

    &__icon {
      left: 14px;

      @media (min-width: $sm-to-md) {
        left: 20px;
      }
    }

    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      right: 14px;

      @media (min-width: $sm-to-md) {
        right: 20px;
      }

      &:focus, &:active {
        outline: none;
      }
    }
  }
}