.speech_recognition_control {
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba( 230, 234, 236, .4);
    cursor: pointer;
    user-select: none;
    &.speech_recognition_control--record {
        opacity: 1;
        left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-image: url('../assets/img/recording_microphone.png');
        background-position: center center;
        background-repeat: no-repeat;
        &.listening {
            opacity: .4;
        }
        &.not_supported {
            font-size: 8px;
            color: red;
        }
    }
    &.speech_recognition_control--absolute {
        position: absolute;
        top: 10px;
        right: 7px;
        left: auto;
        border: 4px;
    }
}