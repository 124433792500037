@mixin auth_elements_placeholder {
    opacity: 1;
    font-size: 14px;
    color: #97A4B1;
}

.auth_layout {
    position: relative;
    height: 100%;
    min-height: 100%;
    .auth_nav_bar {
        position: absolute;
        left: 22px;
        top: 34px;
        z-index: 1;
        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            .logo__cta {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 12px;
                img {
                    width: 100%;
                    border-radius: 50%;
                }
            }
            .logo__text {
                font-size: 16px;
                font-weight: 600;
                color: #fff;
            }
        }
    }
    .auth_layout_container {
        display: flex;
        height: 100%;
        min-height: 100%;
        .auth_layout__left-bar {
            position: relative;
            max-width: 175px;
            flex: 0 0 175px;
            background-color: rgba(0,169,153,0.6);
            background-image: url('../assets/img/jesper-aggergaard-CEM52sAHR80-unsplash.jpg') !important;
            background-position: -812px 0;
            @media (min-width: 1020px) {
                max-width: 450px;
                flex: 0 0 450px;
            }
            .auth_layout__left-bar__overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0,169,153,0.6);
            }
        }
        .auth_layout__content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 100;
            background-color: #F8FAFB;
            .auth_form {
                max-width: 350px;
                width: 350px;
                &.auth_form--wide {
                    max-width: 550px;
                    width: 550px;
                }
                .auth_form__head {
                    font-size: 20px;
                    font-weight: 600;
                    color: #2B2B2B;
                    margin-bottom: 37px;
                }
                .auth_form__body {
                    .form {
                        .auth_form__body__element {
                            .Form__input {
                                margin-top: 0;
                                margin-bottom: 23px;
                                .Form__input__label {
                                    font-size: 11px;
                                    color: #2E2E2E;
                                    font-weight: 600;
                                    margin-bottom: 5px;
                                }
                                input[type='text'],
                                input[type='password'] {
                                    margin: 0;
                                    box-shadow: none;
                                    border: 1px solid #D9E3E7;
                                    background-color: #fff;
                                    height: 51px;
                                    @include auth_elements_placeholder();
                                    &::-webkit-input-placeholder {
                                        @include auth_elements_placeholder();
                                    }
                                    &::-ms-input-placeholder {
                                        @include auth_elements_placeholder();
                                    }
                                    &:-ms-input-placeholder {
                                        @include auth_elements_placeholder();
                                    }
                                    &::-moz-placeholder {
                                        @include auth_elements_placeholder();
                                    }
                                    &:-moz-placeholder {
                                        @include auth_elements_placeholder();
                                    }
                                    &::placeholder {
                                        @include auth_elements_placeholder();
                                    }
                                }
                            }
                        }
                        .auth_form__body__cta {
                            button, a {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                height: 50px;
                                border-radius: 25px;
                                color: #fff;
                                background-color: #00A999;
                                font-size: 16px;
                                font-weight: 600;
                            }
                            &.flex {
                                display: flex;
                                justify-content: space-between;
                                > * {
                                    width: 48%;
                                }
                            }
                        }
                    } 
                }
            }
        }
    }
}
