.patient_list {
    .patient_list__tabs {
        margin-top: 25px;
        margin-bottom: 27px;
    }
    .patient_list__table {
        height: calc(100vh - 310px);
        overflow-y: auto;
        overflow-x: hidden;
    }
}