.anamnese_create {
    .anamnese_create_header {
        padding: 0 0 20px;
        border-bottom: solid 1px #D9E3E7;
        margin-bottom: 30px;
    }
    .anamnese_create_data {
        height: calc(100vh - 260px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 15px;
    }
    .anamnese_create__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .anamnese_create__info__left {
            flex: 0 0 50%;
            max-width: 50%;
            .info_cell {
                display: flex;
                align-items: center;
                &__icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
                    margin-right: 12px;
                    background-color: #fff;
                }
                &__text {
                    p {
                        font-size: 13px;
                        color: #2B2B2B;
                        margin-bottom: 0;
                        &:nth-child(1) {
                            font-size: 18px;
                            font-weight: 600;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
        .anamnese_create__info__right {
            flex: 0 0 50%;
            max-width: 50%;
            .control_cell {
                display: flex;
                justify-content: flex-end;
                &__icon {
                    margin-right: 12px;
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        border: solid 1px #2E2E2E;
                        svg {
                            width: 24px;
                            height: 24px;
                            color: #2E2E2E;
                        }
                    }
                }
                &__btn {
                    a {
                        display: flex;
                        align-items: center;
                        height: 48px;
                        padding: 0 24px;
                        font-size: 16px;
                        font-weight: 500;
                        color: #fff;
                        border-radius: 24px;
                        background-color: #00A999;
                        img {
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
    }
}