@import 'theme_questions';

@mixin form_elements_text_font {
    opacity: 1;
    font-size: 14px;
    color: #2E2E2E;
}

@mixin form_elements_textarea_font {
    opacity: 1;
    font-size: 14px;
    color: #4B4B4B;
}

.anamnese_form {
    .Answer__files {
        padding: 4px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 8px;
        span {
            display: inline-flex;
            align-items: center;
            margin-right: 12px;
        }
        svg {
            margin-right: 6px;
        }
        button {
            display: inline-flex;
            justify-content: center;
        }
    }
    .anamnese_form__giagnosis {
        .anamnese_form__giagnosis__select {
            margin-bottom: 20px;
            .Form__input {
                margin: 0;
            }
            .Form__input__label {
                color: #2E2E2E;
                font-size: 13px;
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 5px;
            }
            .react-autosuggest__suggestions-container--open {
                top: -2px;
                border: 1px solid #D9E3E7;
                border-top: 1px solid transparent;
            }
            .react-autosuggest__input {
                height: 51px;
                border: 1px solid #D9E3E7;
                border-radius: 4px;
                background-color: #fff;
                box-shadow: none;
                margin-top: 0px;
                margin-bottom: 0px;
                @include form_elements_text_font();
                &::-webkit-input-placeholder {
                    @include form_elements_text_font();
                }
                &::-ms-input-placeholder {
                    @include form_elements_text_font();
                }
                &:-ms-input-placeholder {
                    @include form_elements_text_font();
                }
                &::-moz-placeholder {
                    @include form_elements_text_font();
                }
                &:-moz-placeholder {
                    @include form_elements_text_font();
                }
                &::placeholder {
                    @include form_elements_text_font();
                }
            }
        }
    }
    .anamnese_form__additional-info {
        .anamnese_form__additional-info__textarea {
            position: relative;
            .Form__input {
                label {
                    color: #2E2E2E;
                    font-size: 13px;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                }
                textarea,
                .read_only_textarea {
                    border: 1px solid #D9E3E7;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    box-shadow: none;
                    resize: none;
                    width: 100%;
                    height: auto;
                    min-height: 400px;
                    padding: 15px;
                    @include form_elements_textarea_font();
                    ul {
                        padding: 0;
                        margin: 0;
                        list-style-position: inside;
                        li {
                            color: #4B4B4B;
                            font-size: 14px;
                        }
                    }
                }
            }
            .controls {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                height: 45px;
                border-top: solid 1px #D9E3E7;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
                padding: 0 13px;
                &__icons {
                    display: flex;
                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 31px;
                        height: 31px;
                        border: 1px solid #E8E8E8;
                        border-color: transparent;
                        border-radius: 2px;
                        background-color: transparent;
                        margin-right: 10px;
                        svg {
                            color: #919191;
                        }
                        &.active, &:focus, &:active {
                            background-color: #F8FAFB;
                            border-color: #E8E8E8;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}
