:root {
  --button-padding-vertical: 12px;
  --button-padding-horizontal: 15px;
  --button-bg-color: var(--primary-color);
  --button-text-color: var(--white);
  --button-border-color: transparent;
  --button-border-width: 1px;
  --button-border-radius: 3px;

  --button-hover-bg-color: var(--primary-color-hover);
  --button-hover-text-color: var(--white);
}

.Button {
  font: inherit;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  outline: none;

  padding: var(--button-padding-vertical) var(--button-padding-horizontal);
  border: var(--button-border-width) solid var(--button-border-color);
  border-radius: var(--button-border-radius);
  background-color: var(--button-bg-color);
  color: var(--button-text-color);

  &:disabled {
    --button-text-color: var(--button-disabled-text-color);
    --button-bg-color: var(--button-disabled-bg-color);
  }

  &:hover, &:focus {
    color: var(--button-hover-text-color);
    --button-bg-color: var(--button-hover-bg-color);
  }

  &--save {
    --button-bg-color: var(--green);
    --button-text-color: white;
    --button-border-width: 0;

    &:hover, &:focus {
      --button-bg-color: var(--green-darker);
    }
  }

  &--close {
    --button-bg-color: var(--gray);
    --button-text-color: #666;
    --button-border-width: 0;

    &:hover, &:focus {
      --button-bg-color: var(--gray-darker);
    }
  }

  &--delete {
    --button-bg-color: var(--red);
    --button-text-color: white;
    --button-border-width: 0;

    &:hover, &:focus{
      --button-bg-color: var(--red-darker);
    }
  }

  svg + span {
    margin-left: 10px;
  }
}
