@mixin form_input_font {
    opacity: 1;
    font-size: 14px;
    color: #97A4B1;
}

.file_dropzone_modal {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
    .file_dropzone_modal__backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        z-index: 9;
    }
    .file_dropzone_modal__inner {
        position: relative;
        z-index: 10;
        width: 326px;
        border: 1px solid #E6EAEC;
        border-radius: 10px;
        background-color: rgba(255,255,255,0.8);
        box-shadow: 10px 10px 20px 0 rgba(0,0,0,0.1);
        padding: 15px;
    }
    .file_dropzone_modal__name {
        .Form__input {
            .Form__input__disabled {
                min-height: 0;
                height: 51px;
                border: 1px solid #D9E3E7;
                border-radius: 4px;
                background-color: #fff;
                @include form_input_font();
                &::-webkit-input-placeholder {
                    @include form_input_font();
                }
                &::-ms-input-placeholder {
                    @include form_input_font();
                }
                &:-ms-input-placeholder {
                    @include form_input_font();
                }
                &::-moz-placeholder {
                    @include form_input_font();
                }
                &:-moz-placeholder {
                    @include form_input_font();
                }
                &::placeholder {
                    @include form_input_font();
                }
            }
            input[type='text'] {
                height: 51px;
                width: 100%;
                border: 1px solid #D9E3E7;
                border-radius: 4px;
                background-color: #fff;
                @include form_input_font();
                &::-webkit-input-placeholder {
                    @include form_input_font();
                }
                &::-ms-input-placeholder {
                    @include form_input_font();
                }
                &:-ms-input-placeholder {
                    @include form_input_font();
                }
                &::-moz-placeholder {
                    @include form_input_font();
                }
                &:-moz-placeholder {
                    @include form_input_font();
                }
                &::placeholder {
                    @include form_input_font();
                }
            }
        }
    }
    .file_dropzone_modal__zone {
        .FileDropzone {
            padding: 0;
            border-radius: 0;
            border: 0;
        }
        .existed_segment {
            padding: 20px 0;
            .existed_segment__inner {
                display: flex;
                align-items: center;
                flex-direction: column;
            }
            .existed_segment__circle {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: solid 1px #00A999;
                background-color: #00A999;
                color: #fff;
                margin-bottom: 10px;
            }
            .adding_segment__file-info {
                &.edit {
                    margin-bottom: 10px;
                }
                p {
                    color: #2E2E2E;
                    margin-bottom: 0px;
                    text-align: center;
                }
            }
        }
        .adding_segment {
            padding: 20px 0;
            .adding_segment__inner {
                display: flex;
                align-items: center;
                flex-direction: column;
            }
            .adding_segment__circle {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: solid 1px #00A999;
                color: #00A999;
            }
            .adding_segment__text {
                font-size: 14px;
                font-weight: 500;
                color: #00A999;
                margin-bottom: 0px;
                text-align: center;
            }
        }
    }
    .file_dropzone_modal__cnt {
        .theme_btn {
            &:nth-child(1) {
                margin-bottom: 10px;
            }
        }

    }
}
