.basic_upload_button {
    .basic_upload_button__files {
        &__file {
            a {
                display: inline-flex;
                color: #00A999;
                padding: 5px;
                font-size: 13px;
                align-items: center;
                padding: 5px;
            }
        }
    }
}