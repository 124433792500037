@import './breakpoints.scss';

.EmptyPlaceholder {
  display: block;
  margin: 32px auto;
  text-align: center;
  font-size: var(--font-size-6);
  color: var(--text-gray-3);

  @media (min-width: $sm-to-md) {
    position: relative;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
  }

  a {
    color: var(--primary-color);

    &:hover, &:focus, &:active {
      color: var(--primary-color-hover);
    }
  }
}

.SplitLayout {
  --split-layout-gap: 32px;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: $sm-to-md) {
    flex-direction: row;
  }

  &--static {
    flex-direction: row;
  }

  &__left {
    height: 100%;
    flex: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
  }

  &__right {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: var(--split-layout-gap);

    @media (min-width: $sm-to-md) {
      margin-top: 0;
      margin-left: var(--split-layout-gap);
    }
  }

  &__pull-right {
    flex: 0 1 auto;
  }
}