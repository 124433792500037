.Form {
  --item-margin-v: 12px;
  --button-radius: 3px;
  
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  &__centered {
    margin: auto;
    padding: 5% 0;
  }

  &__disabled {
    color: var(--text-gray-4);
  }

  &__title {
    text-align: center;
    font-size: var(--font-size-8);
    line-height: var(--font-size-9);
    font-weight: 400;
    margin-bottom: .5em;
  }

  &__radio-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: var(--item-margin-v) 0;
    padding: 16px;
    font-size: 1.1rem;
    height: 60px;
    line-height: inherit;
  }

  &__checkbox {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-gray-4);

    input[type='checkbox'] {
      cursor: pointer;
      margin-right: 12px;
    }
  }

  &__radio {
    margin-right: 24px;

    label {
      cursor: text;
      margin-bottom: 0;
      font-weight: 400;
    }

    input[type='radio'] {
      cursor: pointer;
      position: static;
      opacity: 1;
      pointer-events: all;
      margin-right: 6px;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  &__raw-input {
    display: block;
    color: var(--main-text-color);
    width: 100%;
    background-color: var(--bg-gray-1);
    border: 2px solid var(--bg-gray-5);
    border-radius: 4px;
    box-shadow: inset 0 2px 2px hsla(0, 0%, 0%, 0.1), inset 0 -2px 0 hsla(0, 0%, 100%, 0.2);
    margin: var(--item-margin-v) 0;
    font-weight: normal;
    height: 100%;
    padding: 16px;
    font-size: 1.25rem;
    outline: 0;
    position: relative;

    &:focus {
      border-color: var(--bg-gray-8);
    }

    &.is-invalid-input {
      border: 2px solid var(--red);
    }

    &:disabled {
      @extend .Form__disabled;
    }
  }

  &__input {
    width: 100%;
    margin-top: var(--item-margin-v);
    margin-bottom: 12px;

    &__error {
      color: var(--red);
      font-weight: 200;
      font-size: 14px;
    }

    &__label {
      width: 100%;
      color: var(--text-gray-4);
      font-size: 1.1em;
      font-weight: 500;
      margin-bottom: 0;
    }

    &__link {
      font-size: 0.8em;
      font-weight: 300;
      color: var(--link-color);
      float: right;

      &:hover {
        color: var(--link-color-hover);
      }
    }

    .Form__tagbox {
      @extend .Form__raw-input;
      display: flex;
      padding: 8px 16px;
      height: auto;
      line-height: 0;
      flex-direction: row;
      flex-wrap: wrap;

      &__tag {
        flex: 0 0 auto;
        display: inline-flex;
        vertical-align: top;
        align-items: center;
        background-color: var(--white);
        padding: 4px 8px;
        margin: 4px 12px 4px 0;
        height: 36px;
        line-height: 32px;
        font-size: 20px;
        border: 2px solid var(--bg-gray-5);
        border-radius: 4px;

        svg {
          cursor: pointer;
          margin-left: 6px;
        }
      }

      .Form__disabled {
        svg {
          cursor: not-allowed;
        }
      }

      input {
        display: inline-flex;
        vertical-align: top;
        overflow: auto;
        flex: 1 0 0px;
        height: 36px;
        line-height: 32px;
        font-size: 20px;
        background-color: transparent;
        border: none;
        border-radius: 0;
        box-shadow: none;
        padding: 6px 0;
        margin: 4px 0;
        font-weight: inherit;
        font-size: inherit;
        outline: none;
        position: static;

        &:focus {
          outline: none;
        }
      }

      &__hint {
        padding: 8px 4px;
        color: var(--text-gray-4);
        font-size: 0.9em;
      }
    }

    .Form__input__disabled {
      @extend .Form__raw-input;
      min-height: 60px;
      cursor: text;
      color: var(--text-gray-4);
    }

    .Form__textarea__disabled {
      @extend .Form__raw-input;
      min-height: 96px;
      cursor: text;
      color: var(--text-gray-4);
    }

    input[type='text'], input[type='password'], textarea {
      @extend .Form__raw-input;
      cursor: text;
    }
  }

  &__link {
    font-weight: 400;
    color: var(--link-color);

    &:hover {
      color: var(--link-color-hover);
    }
  }

  &__buttons {
    margin-top: .5rem;
    border-radius: var(--button-radius);
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
    color: var(--white);

    &__item {
      flex: 1;
      border-radius: inherit;
      color: var(--font-color);
      margin: .5em 0 0 0;
      height: 2.5em;
      line-height: 2.5em;

      &__blue {
        background-color: var(--primary-color);
        box-shadow: var(--elevation-2);

        &:hover, &:focus {
          background-color: var(--primary-color-hover);
          box-shadow: var(--elevation-3);
        }

        &:active {
          box-shadow: var(--elevation-2);
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }

      &__gray {
        background-color: var(--bg-gray-7);
        box-shadow: var(--elevation-2);
        margin-left: 8px;

        &:hover, &:focus {
          background-color: var(--bg-gray-8);
          box-shadow: var(--elevation-3);
          color: var(--font-color);
        }

        &:active {
          box-shadow: var(--elevation-2);
        }
      }
    }
  }
}
