@mixin form_element_font {
    opacity: 1;
    font-size: 14px;
    color: #2E2E2E;
}

.graph_editor {
    position: relative;
    height: 100%;
    .graph_panel {
        height: 100%;
        width: 100%;
        padding: 16px;
        .graph_panel__inner {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .graph_panel_dates_switcher {
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 2;
        &.control_panel_activated {
            top: 130px;
        }
        .graph_panel_dates_switcher__title {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #757575;
            margin-bottom: 5px;
            span {
                margin-left: 7px;
            }
        }
        .graph_panel_dates_switcher__opts {
            .theme_radio {
                margin-bottom: 5px;
                label {
                    color: #757575;
                }
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
    .graph_panel_svg {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        .graph_panel_svg__img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &.fixed_size {
            width: 960px;
            height: 775px;
            @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                height: 620px;
            }
        }
        &__svg {
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 1;
            svg {
                position: absolute;
                cursor: pointer;
            }
        }
    }
    .graph_panel_form_tutorial {
        position: absolute;
        top: calc(50% - 154.5px);
        left: calc(50% - 150.5px);
        z-index: 3;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 309px;
        width: 301px;
        border: 1px solid #E6EAEC;
        border-radius: 10px;
        background-color: rgba(255,255,255,0.8);
        box-shadow: 10px 10px 20px 0 rgba(0,0,0,0.1);
        &__title {
            position: relative;
            margin-top: 20px;
            padding: 0 42px;
            text-align: center;
            color: #2B2B2B;
            font-size: 14px;
            font-weight: 600;
            &:before {
                content: "";
                position: absolute;
                left: calc(50% - 17px);
                top: calc(100% + 12px);
                width: 34px;
                height: 2px;
                border-radius: 2px;
                background-color: #00A999;
            }
        }
        &__desc {
            padding: 0 42px;
            text-align: center;
            color: #2B2B2B;
            font-size: 14px;
            font-weight: 300;
            margin-top: 28px;
        }
        &__cnt {
            margin-top: 20px;
            padding: 0 20px;
        }

    }
    .graph_panel_form {
        position: absolute;
        z-index: 2;
        width: 292px;
        height: 280px;
        border: 1px solid #E6EAEC;
        border-radius: 10px;
        background-color: rgba(255,255,255,0.8);
        box-shadow: 10px 10px 20px 0 rgba(0,0,0,0.1);
        padding: 20px;
        &.read-only {
            height: 180px;
        }
        &.hidden {
            display: none;
        }
        .graph_panel_form__input {
            .graph_panel_form__input__options {
                margin-top: 20px;
                .opts {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 17px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .graph_panel_form__input__slider {
                margin-top: 15px;
                .title {
                    color: #757575;
                    font-size: 12px;
                    font-weight: 500;
                    text-align: center;
                    margin-bottom: 11px;
                }
                .slider {
                    .noUi-target {
                        height: 11px;
                        border: 1px solid #D9E3E7;
                        border-radius: 5.5px;
                        background-color: #fff;
                    }
                    .noUi-pips {
                        height: auto;
                        padding: 0;
                        top: calc(100% + 10px);
                        .noUi-marker {
                            display: none;
                        }
                        .noUi-value {
                            color: #2B2B2B;
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }
                    .noUi-base {
                        .noUi-connect {
                            border-radius: 2.5px;
                            height: 5px;
                            background: linear-gradient(270deg, #ED3D3D 0%, #FFC400 100%);
                            box-shadow: none;
                            top: 2px !important;
                            left: 2px !important;
                        }
                        .noUi-origin {
                            .noUi-handle {
                                height: 24px;
                                width: 24px;
                                background-color: #fff;
                                box-shadow: 0 1px 6px 0 rgba(0,0,0,0.25);
                                border: none;
                                border-radius: 50%;
                                &:before, &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            .graph_panel_form__input__controls {
                margin-top: 40px;
                > * {
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .graph_panel_form__input__desc {
                height: 51px;
                width: 100%;
                border: 1px solid #E6EAEC;
                border-radius: 4px;
                background-color: #FFFFFF;
                box-shadow: none !important;
                outline: none !important;
                padding: 0 18px;
                @include form_element_font();
                &::-webkit-input-placeholder {
                    @include form_element_font();
                }
                &::-ms-input-placeholder {
                    @include form_element_font();
                }
                &:-ms-input-placeholder {
                    @include form_element_font();
                }
                &::-moz-placeholder {
                    @include form_element_font();
                }
                &:-moz-placeholder {
                    @include form_element_font();
                }
                &::placeholder {
                    @include form_element_font();
                }
            }
        }
    }
    .graph_editor__control {
        position: absolute;
        // display: flex;
        top: 15px;
        left: 15px;
        z-index: 2;
        .graph_editor__control__button-wrapper {
            position: relative;
            display: flex;
            align-items: start;
            // margin-right: 25px;
            &.active {
                color: #00A999;
                .graph_editor__control__button-wrapper__button {
                    span {
                        color: #00A999;
                    }
                }
            }
            &:last-child {
                // margin-right: 0px;
                margin-bottom: 0px;
            }
            .graph_editor__control__button-wrapper__button {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 5px;
                span {
                    font-size: 14px;
                    color: #757575;
                    margin-left: 7px;
                }
            }
            .graph_editor__control__button-wrapper__colors {
                position: absolute;
                display: flex;
                left: 0;
                top: 30px;
            }
        }
    }
    .graph_editor__panel {

    }
}
