@mixin form_elements_textarea_font {
    opacity: 1;
    font-size: 14px;
    color: #4B4B4B;
}

.treatment_details {
    .treatment_details_header {
        .theme_header {
            box-shadow: none;
            padding: 24px 0;
        }
    }
    .treatment_details__success {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        box-shadow: inset 0 1px 0 0 #E8E8E8, inset 0 -1px 0 0 #DEE0E1;
        &__left {
            p {
                margin-bottom: 0px;
            }
        }
    }
    .treatment_details__form {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        padding: 0 35px;
        height: calc(100vh - 310px);
        overflow-y: auto;
        overflow-x: hidden;
        .homework_area {
            position: relative;
        }
        .treatment_details__form__control {
            position: absolute;
            top: 50%;
            color: #00A999;
            font-size: 24px;
            &.left {
                left: 0;
            }
            &.right {
                right: 0;
            }
        }
        .form_textarea {
            position: relative;
            .Form__input {
                margin-bottom: 0px;
                label {
                    color: #757575;
                    font-size: 12px;
                    font-weight: 500;
                    margin-bottom: 5px;
                }
                textarea,
                .read_only_textarea {
                    border: 1px solid #D9E3E7;
                    border-bottom-color: transparent;
                    border-top-right-radius: 4px;
                    border-top-left-radius: 4px;
                    background-color: #FFFFFF;
                    box-shadow: none;
                    resize: none;
                    width: 100%;
                    height: auto;
                    min-height: 454px;
                    @include form_elements_textarea_font();
                    .homelessons_list {
                        &.hidden {
                            display: none;
                        }
                    }
                }
            }
            .controls {
                // position: absolute;
                // bottom: 0;
                // left: 0;
                // z-index: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 45px;
                // border-top: solid 1px #D9E3E7;
                background-color: #fff;
                border: solid 1px #D9E3E7;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
                padding: 0 13px;
                &.single {
                    justify-content: flex-end;
                }
                &__add {
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    font-weight: 500;
                    color: #00A999;
                    svg {
                        margin-right: 10px;
                    }
                }
                &__icons {
                    display: flex;
                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 31px;
                        height: 31px;
                        border: 1px solid #E8E8E8;
                        border-color: transparent;
                        border-radius: 2px;
                        background-color: transparent;
                        margin-right: 10px;
                        svg {
                            color: #919191;
                        }
                        &.active, &:focus, &:active {
                            background-color: #F8FAFB;
                            border-color: #E8E8E8;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        &__left {
            flex: 0 0 50%;
            max-width: 50%;
            padding-right: 15px;
        }
        &__right {
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: 15px;
        }
    }
}