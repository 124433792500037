@import '../style/breakpoints.scss';

.MainContainer {
  --banner-height: 0;
  &--with-offline-banner { 
    --banner-height: 96px;
  }
  padding-top: var(--banner-height);
  height: 100%;
  min-width: $xs;

  @media (min-width: $sm-to-md) {
    &--with-offline-banner { 
      --banner-height: 64px
    }
  }

  &__inner {
    min-height: calc(100% - var(--navbar-height));
    display: flex;

    margin: 0 auto;

    @media (min-width: $sm-to-md) {
      padding: 0 var(--padding-sides) 0 var(--padding-sides);
    }

    @media (min-width: $xs-to-sm) {
      padding: 0 var(--padding-sides) 0 var(--padding-sides);
    }
  }

  &__offline {
    position: fixed;
    z-index: var(--offline-banner-index);
    top: 0;
    width: 100%;
    display: flex;
    height: var(--banner-height);
    color: var(--white);
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    background-color: blue;
    font-weight: 500;
    font-size: 16px;
  }
}
